<template>
<ion-page>
  <ion-content class="ion-padding main-content" :fullscreen="true">
    <ion-grid class="ion-text-center" style="height: 100%">
      <ion-row class="ion-align-items-center" style="margin-top: 15vh">
        <ion-col size="12" class="ion-text-center ion-align-self-center">
          <div class="login-page-form-title-div">
            <ion-img class="login-page-logo-img" src="assets/img/logo.png" alt="Q Link Logo"></ion-img>
            <!-- <span class="login-page-title-span">
              Regulation 26A Mandates
            </span> -->
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col size="12" class="ion-text-center ion-align-self-center">
          <PasswordResetEmailForm v-if="passwordResetStep === 'email'" :subTitle="stepSubTitle" :subSubTitle="stepSubSubTitle" />
          <PasswordResetOTPForm v-if="passwordResetStep === 'otp'" :subTitle="stepSubTitle" :subSubTitle="stepSubSubTitle" :email="email" />
          <PasswordResetPasswordForm v-if="passwordResetStep === 'password'" :subTitle="stepSubTitle" :subSubTitle="stepSubSubTitle" />
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>
</ion-page>
</template>

<script>
import {
  defineComponent
} from "vue";
import eventBus from "../services/events";
import PasswordResetEmailForm from '@/components/PasswordResetEmailForm.vue'
import PasswordResetOTPForm from '@/components/PasswordResetOTPForm.vue'
import PasswordResetPasswordForm from '@/components/PasswordResetPasswordForm.vue'

export default defineComponent({
  components: {
    PasswordResetEmailForm,
    PasswordResetOTPForm,
    PasswordResetPasswordForm
  },
  ionViewDidEnter() {},
  ionViewDidLeave() {},
  ionViewWillEnter() {
    this.passwordResetStep = 'email';
    this.stepSubTitle = 'Reset Password';
    this.stepSubSubTitle = 'Please enter your email address below so that we can send you an OTP';
  },
  ionViewWillLeave() {},
  data() {
    return {
      passwordResetStep: 'email',
      stepSubTitle: 'Reset Password',
      stepSubSubTitle: 'Please enter your email address below so that we can send you an OTP',
      email: undefined
    }
  },
  methods: {
    changePasswordResetStep(child) {
      switch (child) {
        case 'email':
          this.passwordResetStep = 'otp';
          this.stepSubTitle = 'Reset Password';
          this.stepSubSubTitle = 'Please enter the OTP you received, your token will expire in 60 minutes';
          break;
        case 'otp':
          this.passwordResetStep = 'password';
          this.stepSubTitle = 'Create new Password';
          this.stepSubSubTitle = 'Please create new password below';
          break;
        default:
          break;
      }
    }
  },
  mounted() {
    eventBus().emitter.on("password-reset-email-sent", () => {
      this.changePasswordResetStep('email')
    });
    eventBus().emitter.on("password-reset-otp-confirmed", () => {
      this.changePasswordResetStep('otp')
    });
  }
});
</script>

<style lang="scss" scoped>
</style>
