<template>
<ion-page>
  <div class="page-header-block">
    <span>Mandate Results </span>
  </div>
  <ion-content>

    <ion-tabs selectedIndex="0" name="myTabs" @ionTabsWillChange="beforeTabChange" @ionTabsDidChange="afterTabChange" @getSelected="select(pending)" @select="is(pending)">
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="top" color="secondary">
        <ion-tab-button v-bind:class=" {active: PendingActive}" tab="pending" href="/mandate/mandateresults/pending">
          <ion-label>Pending</ion-label>
        </ion-tab-button>

        <ion-tab-button v-bind:class=" {active: SuccessfulActive}" tab="successful" href="/mandate/mandateresults/successful">
          <ion-label>Successful</ion-label>
        </ion-tab-button>

        <ion-tab-button v-bind:class=" {active: FailedActive}" tab="failed" href="/mandate/mandateresults/failed">
          <ion-label>Failed</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>

  </ion-content>
</ion-page>
</template>

<script>
import {
  defineComponent
} from 'vue';
import storageService from "../services/storage";
import {
  IonRouterOutlet,
  IonTabBar,
} from '@ionic/vue';

export default defineComponent({
  name: 'MandateResults',
  components: {
    IonRouterOutlet,
    IonTabBar,
  },

  data() {
    return {}
  },
  computed: {

    PendingActive() {
      if (this.$route.name === 'PendingTab') {
        return true
      } else {
        return false
      }
    },

    SuccessfulActive() {
      if (this.$route.name === 'SuccessfulTab') {
        return true
      } else {
        return false
      }
    },

    FailedActive() {
      if (this.$route.name === 'FailedTab') {
        return true
      } else {
        return false
      }
    }
  },

  setup() {

  },

  ionViewDidEnter() {
    this.checkToken();
  },
  ionViewDidLeave() {

  },
  ionViewWillEnter() {},
  ionViewWillLeave() {

  },
  methods: {

    async checkToken() {
      const userAuth = await storageService.getStorage('userAuth', true);
      if (userAuth === undefined) {
        this.$router.push('/login');
      }
    },
  },
  mounted: async function () {

  }
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}

.active {
  color: #fff !important;
  font-weight: bold;
  border-bottom: 2px solid #fff;
  font-size: 16px;

}
</style>
