import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

import { IonContent,
  IonHeader,
  IonFooter,
  IonPage,
  IonTitle,
  IonToolbar,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonImg,
  IonLabel,
  IonButton,
  IonInput,
  IonList,
  IonText,
  IonTabs,
  IonSelect,
  IonSelectOption,
  IonRange,
  IonIcon,
  IonDatetime,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonFab,
  IonFabList,
  IonCheckbox,
  IonAlert,
  IonLoading,
  IonLoadingController ,
  IonRouterOutlet,
  IonTabsbar,
  IonTabButton,
  IonProgressBar,
  IonModal,
  IonItemDivider,
  IonNote,
  IonCardTitle,
} from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Styles variables */
import './theme/styles.scss'

/* PWA Elements */
// eslint-disable-next-line no-unused-vars
import { defineCustomElements } from '@ionic/pwa-elements/loader';

const app = createApp(App)
  .use(IonicVue)
  .use(router);

defineCustomElements(window);
  
router.isReady().then(() => {
  app.mount('#app');
});

app.component('ion-content', IonContent);
app.component('ion-page', IonPage);
app.component('ion-header', IonHeader);
app.component('ion-footer', IonFooter);
app.component('ion-title', IonTitle);
app.component('ion-toolbar', IonToolbar);
app.component('ion-grid', IonGrid);
app.component('ion-row', IonRow);
app.component('ion-col', IonCol);
app.component('ion-item', IonItem);
app.component('ion-img', IonImg);
app.component('ion-label', IonLabel);
app.component('ion-button', IonButton);
app.component('ion-input', IonInput);
app.component('ion-list', IonList);
app.component('ion-text', IonText);
app.component('ion-tabs', IonTabs);
app.component('ion-select', IonSelect);
app.component('ion-select-option', IonSelectOption);
app.component('ion-range', IonRange);
app.component('ion-icon', IonIcon);
app.component('ion-datetime', IonDatetime);
app.component('ion-card', IonCard);
app.component('ion-card-header', IonCardHeader);
app.component('ion-card-content', IonCardContent);
app.component('ion-fab', IonFab);
app.component('ion-fab-list', IonFabList);
app.component('ion-checkbox', IonCheckbox);
app.component('ion-alert', IonAlert);
app.component('ion-loading', IonLoading);
app.component('ion-loading-controller', IonLoadingController);
app.component('ion-router-outlet', IonRouterOutlet);
app.component('ion-tab-bar', IonTabsbar);
app.component('ion-tab-button', IonTabButton);
app.component('ion-progress-bar', IonProgressBar);
app.component('ion-modal', IonModal);
app.component('ion-item-divider', IonItemDivider)
app.component('ion-note', IonNote);
app.component('ion-card-title', IonCardTitle)

