<template>
<div>
  <div v-if="deviceOffline" class="toolbar-offline">
    <span>You are offline</span>
  </div>
  <div v-if="headerType === 'HeaderBlocks'">
    <ion-grid class="ion-no-padding ion-no-margin">
      <ion-row class="ion-no-padding ion-no-margin toolbar-squares">
        <ion-col class="ion-no-padding ion-no-margin">
          <div></div>
        </ion-col>
        <ion-col class="ion-no-padding ion-no-margin">
          <div></div>
        </ion-col>
        <ion-col class="ion-no-padding ion-no-margin">
          <div></div>
        </ion-col>
        <ion-col class="ion-no-padding ion-no-margin">
          <div></div>
        </ion-col>
        <ion-col class="ion-no-padding ion-no-margin">
          <div></div>
        </ion-col>
        <ion-col class="ion-no-padding ion-no-margin">
          <div></div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <div class="toolbar-default" v-if="headerType === 'Default'">
    <ion-img src="assets/icon/icon.png" alt="Q Link Icon"></ion-img><span>Regulation 26A Mandates</span>
  </div>
  <div class="toolbar-none" v-if="headerType === 'None'">
  </div>
</div>
</template>

<script>
import {
  defineComponent
} from 'vue';
import {
  Network
} from '@ionic-native/network';
import eventBus from '@/services/events';

export default defineComponent({
  name: 'AppHeader',
  components: {},
  data() {
    return {
      network: Network,
      disconnectSubscription: undefined,
      connectSubscription: undefined,
      headerType: 'Default',
      deviceOffline: false
    }
  },
  methods: {
    changeType(route) {
      switch (route.name) {
        case 'Login':
        case 'RegisterPage':
        case 'PasswordReset':
          return 'HeaderBlocks';
        case 'Home':
        case 'CreateMandate':
        case 'MandateResults':
        case 'PendingTab':
        case 'SuccessfulTab':
        case 'FailedTab':
          return 'Default';
        default:
          console.log(route.name);
          return 'None';
      }
    }
  },
  mounted() {
    this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
      this.deviceOffline = true;
    });
    this.connectSubscription = this.network.onConnect().subscribe(() => {
      this.deviceOffline = false;
      eventBus().emitter.emit("device-on-connect", {});
    });
    this.headerType = this.changeType(this.$route);

  },
  unmounted() {
    if (this.disconnectSubscription !== undefined) {
      this.disconnectSubscription.unsubscribe();
    }
    if (this.connectSubscription !== undefined) {
      this.connectSubscription.unsubscribe();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.headerType = this.changeType(to)
      }
    }
  }
});
</script>

<style lang="scss" scoped>
</style>
