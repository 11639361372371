<template>
<div class="page-header-block-steps">
  <span class="page-header-block-steps-step">Step 2/4</span>
  <span class="page-header-block-steps-text">Capture Mandate Detail</span>
</div>
<ion-grid class="main-content" style="padding: 15px;">
  <form>
    <ion-row>
      <ion-col size="12" class="ion-text-left ion-align-self-center input-container">
        <ion-label position="stacked" class="input-container-label">Policy Reference</ion-label>
        <ion-input v-model="policyReferenceNumber" placeholder="Policy Reference Number" type="text" class="input-container-input"></ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-text-left ion-align-self-center input-container">
        <ion-label position="stacked" class="input-container-label">Deduction Amount</ion-label>
        <ion-input v-model="deductionAmount" placeholder="Enter Amount" type="number" class="input-container-input"></ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-text-left ion-align-self-center input-container">
        <ion-label position="stacked" class="input-container-label">Deduction Start Date</ion-label>
        <ion-select :interface-options="customActionSheetOptions" interface="action-sheet" v-model="deductionDate" class="input-container-select" placeholder="Start Date">
          <ion-select-option v-for="(item, i) in availableDates" :key="i" :value="item.value">{{item.text}}</ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
  </form>
  <ion-row style="margin-top: 15px;">
    <ion-col size="6" class="ion-text-left ion-align-self-center">
      <ion-button shape="round" fill="outline" color="primary" class="create-mandate-btn" @click="reset()">
        Reset
      </ion-button>
    </ion-col>
    <ion-col size="6" class="ion-text-right ion-align-self-center">
      <ion-button shape="round" color="primary" class="create-mandate-btn" @click="nextStep()">
        Continue
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
</template>

<script>
import {
  defineComponent
} from 'vue';
import eventBus from "../services/events";
import alertsService from "../services/alerts";
import store from "../services/store";

export default defineComponent({
  name: 'CreateMandateCaptureMandate',
  props: {},
  components: {},
  data() {
    return {
      policyReferenceNumber: '',
      deductionAmount: '',
      deductionDate: '',
      availableDates: [],
      months: ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ],
      days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
    }
  },
  methods: {
    reset() {
      this.policyReferenceNumber = ''
      this.deductionAmount = ''
      this.deductionDate = ''
    },
    nextStep() {
      if (!(this.policyReferenceNumber && this.deductionAmount && this.deductionDate)) {
        alertsService.showAlert('Error', 'Invalid details entered. Please enter the required details.');
        return;
      }
      let startDateObject = new Date(this.deductionDate);
      let startDate = startDateObject.getFullYear() + (startDateObject.getMonth() < 10 ? ('0' + startDateObject.getMonth()) : startDateObject.getMonth()) + (startDateObject.getDate() < 10 ? ('0' + startDateObject.getDate()) : startDateObject.getDate());
      let startDateString = this.days[startDateObject.getDay()] + ', ' + this.months[startDateObject.getMonth()] + ' ' + (startDateObject.getDate() < 10 ? ('0' + startDateObject.getDate()) : startDateObject.getDate()) + ' ' + startDateObject.getFullYear();
      let startDateShortString = (startDateObject.getDate() < 10 ? ('0' + startDateObject.getDate()) : startDateObject.getDate()) + '/' + (startDateObject.getMonth() < 10 ? ('0' + startDateObject.getMonth()) : startDateObject.getMonth()) + '/' + startDateObject.getFullYear();
        store.setCaptureMandateDetails({
          amount: this.deductionAmount,
          reference: this.policyReferenceNumber,
          startDate: startDate,
          startDateString: startDateString,
          startDateShortString: startDateShortString
        });
      eventBus().emitter.emit("create-mandate-next-step", {
        fromStep: 'captureMandate'
      });
    },
    initiateComponent() {
      const currentDate = new Date();
      for (let i = 0; i < 4; i++) {
        const nextDate = new Date(((currentDate.getMonth() + i) % 12) < currentDate.getMonth() ? (currentDate.getFullYear() + 1) : currentDate.getFullYear(), ((currentDate.getMonth() + i) % 12), 1);
        this.availableDates.push({
          text: this.months[nextDate.getMonth()],
          value: nextDate.toString()
        })
      }
    }
  },
  mounted() {
    this.initiateComponent();
  }
});
</script>

<style scoped>

</style>
