<template>
<ion-grid class="ion-no-padding">
  <ion-row class="ion-align-items-center">
    <ion-col size="12" class="ion-padding-start ion-text-left ion-align-self-center">
      <div class="login-page-form-subtitle-div">
        <span>
          {{ subTitle }}
        </span>
      </div>
      <div class="login-page-form-subsubtitle-div">
        <span>
          {{ subSubTitle }}
        </span>
      </div>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col size="12" class="ion-text-center ion-align-self-center">
      <ion-list lines="none" style="padding-bottom: 40px;">
        <ion-item id="rounded" class="input-container">
          <ion-label position="stacked" class="input-container-label">Password</ion-label>
          <ion-input v-model="password" placeholder="Your Password" type="password" autocomplete="new-password" class="input-container-input"></ion-input>
        </ion-item>
        <ion-item id="rounded" class="input-container">
          <ion-label position="stacked" class="input-container-label">Confirm Password</ion-label>
          <ion-input v-model="passwordConfirm" placeholder="Please confirm your Password" type="password" class="input-container-input"></ion-input>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col size="12" class="ion-text-center ion-align-self-center">
      <ion-button @click="attemptResetPassword()" shape="round" color="primary" class="btn-Primary">
        Reset Password
      </ion-button>
      <div class="login-page-form-text-div">
        <span>
          Go back to <a @click="toLogin()">Login</a>
        </span>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
</template>

<script>
import {
  defineComponent
} from 'vue';
import axios from 'axios';
import alertsService from "../services/alerts";
import store from "../services/store";
import {
  loadingController
} from "@ionic/vue";
import {
  environment
} from '../environments/environments';

export default defineComponent({
  name: 'PasswordResetPasswordForm',
  props: {
    subTitle: undefined,
    subSubTitle: undefined
  },
  components: {

  },
  data() {
    return {
      password: undefined,
      passwordConfirm: undefined
    }
  },
  methods: {
    toLogin() {
      this.$router.push('/login');
    },
    async attemptResetPassword() {
      if (this.password !== undefined) {
        if (this.password === this.passwordConfirm) {
          const resetPasswordResults = await this.resetPassword(this.password, this.passwordConfirm);
          if (resetPasswordResults && resetPasswordResults.statusCode && (resetPasswordResults.statusCode === '204' || resetPasswordResults.statusCode === 204)) {
            store.setPasswordResetOccurred(true);
            store.clearPasswordResetObject();
            this.password = '';
            this.passwordConfirm = '';
            this.toLogin();
          } else if (resetPasswordResults && resetPasswordResults.message) {
            alertsService.showAlert('Error', resetPasswordResults.message);
          } else {
            alertsService.showAlert('Error', 'An error has occurred, please try again or contact us.');
          }
        } else {
          alertsService.showAlert('Error', 'Passwords do not match.');
        }
      } else {
        alertsService.showAlert('Error', 'Passwords can not be empty.');
      }
    },
    async resetPassword(password, passwordConfirm) {
      const loading = await loadingController.create({
        message: 'Loading, Please Wait',
      });
      await loading.present();
      return new Promise((resolve) => {
        const headers = {
          "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
        }
        const data = {
          idNumber: parseInt(store.state.passwordResetObject.idNumber),
          token: store.state.passwordResetObject.token,
          password: password,
          password_confirmation: passwordConfirm
        }
        axios.defaults.timeout = 30000
        axios.post(environment.justsolveAPIM + '/resetPassword', data, {
          headers
        }).then((response) => {
          loadingController.dismiss();
          resolve(response.data);
        }).catch(() => {
          loadingController.dismiss();
          resolve(false);
        })
      });
    }
  },
  mounted() {}
});
</script>

<style scoped>

</style>
