import { Storage } from "@capacitor/storage";
import { SecureStorageEcho } from "@ionic-native/secure-storage-echo";

let secureStorageEcho = undefined

const storageService = {
  async setStorage (key, jsonValue, secure = false) {
    let value = JSON.stringify(jsonValue);
    if (!secure) {
      await Storage.set({
        key: key,
        value: value,
      });
    } else {
      this.setSecureStorage(key, value);
    }
  },

  async getStorage (key, secure = false) {
    let value;
    let jsonValue;
    if (!secure) {
      value = await Storage.get({ key: key });
    } else {
      value = await this.getSecureStorage(key);
    }
    if (value !== undefined) {
      jsonValue = JSON.parse(value);
      return jsonValue;
    } else {
      return {};
    }
  },

  async removeStorage (key, secure = false) {
    if (!secure) {
      await Storage.remove({ key: key });
    } else {
      this.removeSecureStorage(key);
    }
  },

  async initSecureStorage () {
    if (secureStorageEcho) {
      return true;
    }
    secureStorageEcho = await SecureStorageEcho.create(
      "Regulation 26A Mandate App"
      );
    return true;
  },

  async setSecureStorage (myKey, myValue) {
    if (!secureStorageEcho) {
      await this.initSecureStorage();
    }
    if (secureStorageEcho) {
      try {
        const returnedValue = secureStorageEcho.set(
          myKey,
          myValue
        );
        return returnedValue;
      } catch (err) {
        return undefined;
      }
    } else {
      return undefined;
    }
  },

  async getSecureStorage (myKey) {
    if (!secureStorageEcho) {
      await this.initSecureStorage();
    }
    if (secureStorageEcho) {
      try {
        const returnedValue = await secureStorageEcho.get(
          myKey
        );
        return returnedValue;
      } catch (err) {
        return undefined;
      }
    } else {
      return undefined;
    }
  },

  async removeSecureStorage (myKey) {
    if (!secureStorageEcho) {
      await this.initSecureStorage();
    }
    if (secureStorageEcho) {
      try {
        const returnedValue = secureStorageEcho.remove(
          myKey
        );
        return returnedValue;
      } catch (err) {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
}

export default storageService