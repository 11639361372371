<template>
<ion-app>
  <ion-page>
    <ion-header :translucent="true">
      <AppHeader />
    </ion-header>
    <ion-content>
      <ion-router-outlet />
    </ion-content>
    <ion-footer>
      <AppFooter />
    </ion-footer>
  </ion-page>
</ion-app>
</template>

<script>
import {
  IonApp,
  IonRouterOutlet
} from '@ionic/vue';
import {
  defineComponent
} from 'vue';
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter.vue';
import storageService from './services/storage';
import axios from 'axios';
import {
  environment
} from './environments/environments';
import eventBus from './services/events';
import {
  Network
} from '@capacitor/network';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    AppHeader,
    AppFooter
  },
  data() {
    return {}
  },
  methods: {
    async onDeviceReady() {
      storageService.initSecureStorage();
      const status = await Network.getStatus();
      if (status.connected === true) {
        this.performReconnectTasks();
      }
    },
    async performReconnectTasks() {
      const userAuth = await storageService.getStorage('userAuth', true);
      if (userAuth === undefined) {
        return;
      }
      this.submitOfflineMandates(userAuth.tokenType, userAuth.accessToken)
    },
    async submitOfflineMandates(tokenType, accessToken) {
      const offlineMandates = await storageService.getStorage('pendingOfflineMandates', true);
      if (!(offlineMandates && offlineMandates.length > 0)) {
        return;
      }
      for (let i = 0; i < offlineMandates.length; i++) {
        const createMandateResults = await this.captureSingleMandate(tokenType, accessToken, offlineMandates[i].biometrics, offlineMandates[i].mandate);
        if (createMandateResults && (createMandateResults.statusCode === 200 || createMandateResults.statusCode === '200')) {
          offlineMandates[i].sent = true;
        }
      }
      const offlineMandatesNotSent = offlineMandates.filter(x => x.sent === undefined || x.sent === false);
      await storageService.setStorage('pendingOfflineMandates', offlineMandatesNotSent, true);
    },
    async captureSingleMandate(tokenType, accessToken, biometrics, mandate) {
      return new Promise((resolve) => {
        const headers = {
          "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
        }
        const data = {
          tokenType: tokenType,
          accessToken: accessToken,
          biometrics: biometrics,
          mandate: mandate
        }
        axios.defaults.timeout = 30000
        axios.post(environment.justsolveAPIM + '/createMandate', data, {
          headers
        }).then((response) => {
          resolve(response.data);
        }).catch(() => {
          resolve(false);
        })
      });
    }
  },
  mounted() {
    document.addEventListener("deviceready", this.onDeviceReady(), false);
    eventBus().emitter.on("device-on-connect", () => {
      this.performReconnectTasks();
    });
  },
  unmounted() {
    eventBus().emitter.off("device-on-connect", {});
  }
});
</script>
