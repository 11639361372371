<template>
<div>
    <div class="footer-qlinklogo" v-if="footerType === 'Logo'">
        <div class="qlink-poweredby-image-div">
            <ion-img src="assets/img/QLink.png" alt="Q Link Logo"></ion-img>
        </div>
    </div>
    <div class="footer-toolbar" v-if="footerType === 'Toolbar'">
        <ion-grid>
            <ion-row class="ion-justify-content-between">
                <ion-col size="3">
                    <div class="footer-toolbar-logout-icon-container">
                        <span @click="logOut()">
                            <ion-icon :icon="logOutOutline" />
                        </span>
                    </div>
                    <div class="footer-toolbar-logout-text-container">
                        <span @click="logOut()">
                            Log Out
                        </span>
                    </div>
                </ion-col>
                <ion-col size="3">
                    <div class="footer-toolbar-home-icon-container">
                        <span @click="goToHome()">
                            <ion-icon :icon="home" />
                        </span>
                    </div>
                    <div class="footer-toolbar-home-text-container">
                        <span @click="goToHome()">
                            Home
                        </span>
                    </div>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
    <div class="footer-none" v-if="footerType === 'None'">
    </div>
</div>
</template>

<script>
import {
    defineComponent
} from 'vue';
import {
    home,
    logOutOutline
} from 'ionicons/icons';
import storageService from "../services/storage";
import eventBus from "../services/events";
import store from '@/services/store';

export default defineComponent({
    name: 'AppFooter',
    components: {},
    setup() {
        return {
            home,
            logOutOutline
        }
    },
    data() {
        return {
            footerType: 'None'
        }
    },
    methods: {
        changeType(route) {
            switch (route.name) {
                case 'Login':
                case 'RegisterPage':
                case 'PasswordReset':
                    return 'Logo';
                case 'Home':
                case 'CreateMandate':
                case 'MandateResults':
                case 'PendingTab':
                case 'SuccessfulTab':
                case 'FailedTab':
                    return 'Toolbar';
                default:
                    console.log(route.name);
                    return 'None';
            }
        },
        async goToHome() {
            eventBus().emitter.emit("footer-go-to-home", {});
            const userAuth = await storageService.getStorage('userAuth', true);
            const userMe = await storageService.getStorage('userMe', true);
            if (userAuth && userMe === undefined) {
                this.$router.push('/');
            }
            this.$router.push('/home');
            //if (store.state.isAuthenticated == true){
            //    this.$router.push('/home');
            //} else{
            //    this.$router.push('/');
            //}
        },
        async logOut() {
            eventBus().emitter.emit("footer-log-out", {});
            const userAuth = await storageService.getStorage('userAuth', true);
            if (userAuth && userAuth.refreshToken) {
                userAuth.accessToken = undefined;
                userAuth.expires = undefined;
                await storageService.setStorage('userAuth', userAuth, true);
                store.setIsAuthenticated(false)
            } else {
                await storageService.removeStorage('userAuth', true);
                //await storageService.removeStorage('userMe', true);
                store.setIsAuthenticated(false)
            }
            //Put here till further notice
            //await storageService.removeStorage('userAuth', true);
            //await storageService.removeStorage('userMe', true);
            //Put here till further notice
            store.setIsAuthenticated(false)
            this.$router.push('/');
        }
    },
    mounted() {
        this.footerType = this.changeType(this.$route)
    },
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                this.footerType = this.changeType(to)
            }
        }
    }

});
</script>

<style lang="scss" scoped>
</style>
