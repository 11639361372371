<template>
<ion-page>
  <ion-content class="main-content" :fullscreen="true">
    <CreateMandateBiometricConsent v-if="createMandateStep === 'biometricConsent'" />
    <CreateMandateCaptureClient v-if="createMandateStep === 'captureClient'" />
    <CreateMandateCaptureMandate v-if="createMandateStep === 'captureMandate'" />
    <CreateMandateClientConsent v-if="createMandateStep === 'clientConsent'" />
    <CreateMandateGenerateMandate v-if="createMandateStep === 'generateMandate'" />
  </ion-content>
</ion-page>
</template>

<script>
import {
  defineComponent
} from 'vue';
import {
  VoiceRecorder
} from 'capacitor-voice-recorder';
import CreateMandateBiometricConsent from "@/components/CreateMandateBiometricConsent.vue";
import CreateMandateCaptureClient from "@/components/CreateMandateCaptureClient.vue";
import CreateMandateCaptureMandate from "@/components/CreateMandateCaptureMandate.vue";
import CreateMandateClientConsent from "@/components/CreateMandateClientConsent.vue";
import CreateMandateGenerateMandate from "@/components/CreateMandateGenerateMandate.vue";
import storageService from "../services/storage";
import eventBus from "../services/events";
import alertsService from "../services/alerts";

export default defineComponent({
  components: {
    CreateMandateBiometricConsent,
    CreateMandateCaptureClient,
    CreateMandateCaptureMandate,
    CreateMandateClientConsent,
    CreateMandateGenerateMandate
  },
  data() {
    return {
      createMandateStep: 'biometricConsent'
    }
  },
  ionViewDidEnter() {
    this.initVoiceRecording();
    eventBus().emitter.on("create-mandate-next-step", (data) => {
      this.nextStep(data);
    });
    this.checkToken();
    this.createMandateStep = 'biometricConsent';
  },
  ionViewDidLeave() {
    eventBus().emitter.off("create-mandate-next-step", {});
  },
  ionViewWillEnter() {},
  ionViewWillLeave() {

  },
  methods: {
    async checkToken() {
      const userAuth = await storageService.getStorage('userAuth', true);
      if (userAuth === undefined) {
        this.$router.push('/login');
      }
    },
    nextStep(data) {
      switch (data.fromStep) {
        case 'biometricConsent':
          this.createMandateStep = 'captureClient';
          break;
        case 'captureClient':
          this.createMandateStep = 'captureMandate';
          break;
        case 'captureMandate':
          this.createMandateStep = 'clientConsent';
          break;
        case 'clientConsent':
          this.createMandateStep = 'generateMandate';
          break;
        case 'generateMandate':
          this.createMandateStep = 'biometricConsent';
          break;
        default:
          break;
      }
    },
    async initVoiceRecording() {
      let canDeviceVoiceRecordResults = await VoiceRecorder.canDeviceVoiceRecord().then((result) => result.value);
      let requestAudioRecordingPermissionResults = await VoiceRecorder.requestAudioRecordingPermission().then((result) => result.value);
      let hasAudioRecordingPermissionResults = await VoiceRecorder.hasAudioRecordingPermission().then((result) => result.value);
      if (!canDeviceVoiceRecordResults || !requestAudioRecordingPermissionResults || !hasAudioRecordingPermissionResults) {
        alertsService.showAlert('Error', 'Voice Recording is required to create a mandate. Please allow permission before trying again.');
        this.$router.push('/home');
        return;
      }
    }
  },
  mounted() {}
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
