<template>
<ion-page>
    <ion-content class="ion-padding main-content" :fullscreen="true">
        <ion-grid class="ion-text-center" style="height: 100%">
            <ion-row class="ion-align-items-center" style="margin-top: 15vh">
                <ion-col size="12" class="ion-text-center ion-align-self-center">
                    <div :class="loginType === 'none' ? 'login-page-home-title-div' : 'login-page-form-title-div'">
                        <ion-img class="login-page-logo-img" src="assets/img/logo.png" alt="Q Link Logo"></ion-img>
                        <!-- <span class="login-page-title-span">
                            Regulation 26A Mandates
                        </span> -->
                    </div>
                </ion-col>
            </ion-row>
            <ion-row v-if="loginType === 'none'" class="ion-align-items-center">
                <ion-col size="12" class="ion-text-center ion-align-self-center">
                    <!--
                    <ion-button v-if="biometricAvailable" shape="round" color="primary" class="btn-Primary" @click="biometricLogin('default')">
                        Biometric Login
                    </ion-button>
                    -->
                    <ion-button v-if="biometricAvailable" shape="round" color="primary" class="btn-Primary" @click="checkCredential()">
                        Biometric Login
                    </ion-button>
                    <ion-button shape="round" fill="outline" color="primary" class="btn-Primary" @click="credentialLogin('default')">
                        Credential Login
                    </ion-button>
                </ion-col>
            </ion-row>
            <ion-row v-if="loginType === 'credential'" class="ion-align-items-center">
                <ion-col size="12" class="ion-text-center ion-align-self-center">
                    <LoginForm :subTitle="loginFormSubTitle" :subSubTitle="loginFormSubSubTitle" />
                </ion-col>
            </ion-row>
            <!--
            <ion-row v-if="loginType === 'biometric'" class="ion-align-items-center">
                <ion-col size="12" class="ion-text-center ion-align-self-center">
                    <LoginBiometricForm :subTitle="loginFormSubTitle" :subSubTitle="loginFormSubSubTitle" />
                </ion-col>
            </ion-row>
            -->
        </ion-grid>
    </ion-content>
</ion-page>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";
//import LoginBiometricForm from "@/components/LoginBiometricForm.vue";
import { defineComponent } from "vue";
import store from "../services/store";
import storageService from "../services/storage";
import eventBus from "../services/events";
import validationService from "@/services/validation";
import { NativeBiometric } from "capacitor-native-biometric";

export default defineComponent({
    components: {
        LoginForm,
        //LoginBiometricForm
    },
    ionViewDidEnter() {},
    ionViewDidLeave() {},
    async ionViewWillEnter() {
//TGJ Why is this called twice?
        this.checkRefreshToken()
        this.checkRefreshToken();
        if (store.state.passwordResetOccurred === true) {
            store.setisCredentialLogin(true)
            this.credentialLogin('password');
            store.setPasswordResetOccurred(false);
        } else {
            store.setisCredentialLogin(false)
            this.loginType = 'none';
            this.loginFormSubTitle = '';
            this.loginFormSubSubTitle = '';
        }
    },
    ionViewWillLeave() {},
    data() {
        return {
            loginType: 'none',
            loginFormSubTitle: '',
            loginFormSubSubTitle: '',
            showCredential: true,
            biometricAvailable: (
                validationService.isBiometricAvailable() && 
                validationService.hasBiometricCredentials()
            )
        }
    },
    methods: {
        credentialLogin(type) {
            this.loginType = 'credential';
            if (type === 'default') {
                this.loginFormSubTitle = 'Credential Login';
                this.loginFormSubSubTitle = 'Please log in below';
            } else if (type === 'password') {
                this.loginFormSubTitle = 'Password successfully changed';
                this.loginFormSubSubTitle = 'Please log in below';
            }
        },
        biometricLogin() {
            this.disabled = true;
            store.setisCredentialLogin(false)
            this.loginType = 'biometric';
            this.loginFormSubTitle = 'Biometric Login';
            this.loginFormSubSubTitle = 'Please log using your thumbprints below';
        },
//Biometric validations #############################################################################################
        async checkCredential() {
            store.setisCredentialLogin(false)
            if (validationService.isBiometricAvailable()) {
                // Get user's credentials
                var credentials = await NativeBiometric.getCredentials({ 
                    server: "Regulation 26A Mandates",
                })
                if (credentials) {
                    // Authenticate using biometrics before logging the user in
                    try {
                        await NativeBiometric.verifyIdentity({
                            reason: 'Biometric Access Control',
                            title: 'Regulation 26A Mandates',
                            //subtitle: 'logon using biometrics',
                            //description: 'Biometric descriptiont hing here maybe multipleline description!',
                            //negativeButtonText: 'Return/Cancel'
                        })

                        validationService.attemptLogin(credentials.username, credentials.password)
                    }
                    catch(err) {
                        return false;
                    }                
                    return true;

                } else {
                    return false;
                }
            } else {
                return false;
            }
        },

        // Not sure this is used at this time! 13/07/2022 TGJ
        async checkBiometrics() {
            const biometricTemplate = await storageService.getStorage('biometricTemplate', true);
            if (!biometricTemplate || !biometricTemplate.leftWsq || !biometricTemplate.leftTemplate || !biometricTemplate.rightWsq || !biometricTemplate.rightTemplate) {
                storageService.removeStorage('userAuth', true);
                storageService.removeStorage('biometricTemplate', true);
                this.$router.push('/register');
            }
        },

        async checkRefreshToken() {
            const userAuth = await storageService.getStorage('userAuth', true);
            if (userAuth && userAuth.refreshToken && userAuth.refreshToken !== '') {
                this.showCredential = false;
            } else {
                this.showCredential = true;
            }
        }
    },
    mounted() {
        eventBus().emitter.on("biometric-go-to-login", () => {
            this.biometricLogin('default');
        });
    }
});
</script>

<style lang="scss" scoped>
</style>
