<template>
<ion-page>
    <ion-content class="ion-padding main-content" :fullscreen="true">
        <ion-row class="ion-align-items-center">
            <ion-col size="12" class="ion-text-center ion-align-self-center">
                <div :class="loginType === 'none' ? 'login-page-home-title-div' : 'login-page-form-title-div'">
                    <ion-img class="login-page-logo-img" src="assets/img/logo.png" alt="Q Link Logo"></ion-img>
                    <!-- <span class="login-page-title-span">
                        Regulation 26A Mandates
                    </span> -->
                </div>
            </ion-col>
        </ion-row>

        <RegisterForm />

    </ion-content>
</ion-page>
</template>

<script>
import {
    defineComponent
} from 'vue';
import RegisterForm from '../components/RegisterForm.vue'

export default defineComponent({
    name: 'RegisterPage',
    components: {
        RegisterForm
    },
    data() {
        return {

        }
    },
    methods: {},
    mounted() {

    }
});
</script>

<style lang="scss" scoped>
</style>
