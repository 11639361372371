const store = {
  /*
  *****************************
  *****************************
            Store
  *****************************
  *****************************
  */
  
  state: {
    passwordResetObject: {
      token: '',
      idNumber: ''
    },
    passwordResetOccurred: false,
    isAuthenticated: false,
    isCredentialLogin: false,
    captureMandateDetails: {
      insurer: '',
      amount: 0,
      reference: '',
      startDate: 0,
      startDateString: '',
      startDateShortString: '',
      surname: '',
      firstName: '',
      initials: ''
    },
    captureMandateBiometrics: {
      deviceId: 0,
      idNumber: 0,
      leftTemplate: '',
      leftWsq: '',
      rightTemplate: '',
      rightWsq: '',
      face: '',
      voice: ''
    }
  },


  /*
    *****************************
    *****************************
              Mutations
    *****************************
    *****************************
  */

  setPasswordResetIDNumber (value) {
    this.state.passwordResetObject.idNumber = value;
  },
  setPasswordResetToken (value) {
    this.state.passwordResetObject.token = value;
  },
  clearPasswordResetObject () {
    this.state.passwordResetObject.token = '';
    this.state.passwordResetObject.idNumber = '';
  },
  setPasswordResetOccurred (value) {
    this.state.passwordResetOccurred = value;
  },
  setIsAuthenticated ( value ) {
    this.state.isAuthenticated = value;
  },
  setisCredentialLogin  ( value ) {
    this.state.isCredentialLogin = value;
  },

  setCaptureMandateClientDetails (value) {
    this.state.captureMandateDetails.insurer = value.insurer;
    this.state.captureMandateDetails.surname = value.surname;
    this.state.captureMandateDetails.firstName = value.firstName;
    this.state.captureMandateDetails.initials = value.initials;
  },
  setCaptureMandateDetails (value) {
    this.state.captureMandateDetails.amount = value.amount;
    this.state.captureMandateDetails.reference = value.reference;
    this.state.captureMandateDetails.startDate = value.startDate;
    this.state.captureMandateDetails.startDateString = value.startDateString;
    this.state.captureMandateDetails.startDateShortString = value.startDateShortString;
  },
  setCaptureMandateBiometrics (value) {
    this.state.captureMandateBiometrics.deviceId = value.deviceId;
    this.state.captureMandateBiometrics.idNumber = value.idNumber;
    this.state.captureMandateBiometrics.leftTemplate = value.leftTemplate;
    this.state.captureMandateBiometrics.leftWsq = value.leftWsq;
    this.state.captureMandateBiometrics.rightTemplate = value.rightTemplate;
    this.state.captureMandateBiometrics.rightWsq = value.rightWsq;
    this.state.captureMandateBiometrics.face = value.face;
  },
  setCaptureMandateVoiceBiometrics (value) {
    this.state.captureMandateBiometrics.voice = value.voice;
  },
  clearCaptureMandate () {
    this.state.captureMandateDetails = {
      insurer: '',
      amount: 0,
      reference: '',
      startDate: 0,
      startDateString: '',
      startDateShortString: '',
      surname: '',
      firstName: '',
      initials: ''
    },
    this.state.captureMandateBiometrics = {
      deviceId: 0,
      idNumber: 0,
      leftTemplate: '',
      leftWsq: '',
      rightTemplate: '',
      rightWsq: '',
      face: '',
      voice: ''
    }
  }

}

export default store