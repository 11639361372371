<template>
<div class="page-header-block-steps">
  <span class="page-header-block-steps-step">Step 3/4</span>
  <span class="page-header-block-steps-text">Client Consent</span>
</div>
<ion-grid class="main-content" style="padding: 15px;">
  <ion-row>
    <ion-col size="12" class="ion-text-left ion-align-self-center">
      <ion-card class="upload-container consent-container">
        <ion-row class="ion-align-items-center">
          <ion-col class="ion-align-self-center">
            <ion-card-header>
              <span class="create-mandate-consent-heading-span">Please record the client saying the below:</span>
            </ion-card-header>
            <ion-card-content class="create-mandate-consent-script" v-if="captureMandateDetails && captureMandateDetails.firstName && captureMandateBiometrics && captureMandateBiometrics.idNumber">
              <span>I, <strong>{{captureMandateDetails.firstName + ' ' + captureMandateDetails.surname}}</strong></span><br>
              <span>ID NUMBER: <strong>{{captureMandateBiometrics.idNumber}}</strong></span><br>
              <span>CONSENT TO A POLICY OF TYPE: <strong>FUNERAL</strong></span><br>
              <span>FOR THE AMOUNT OF: <strong>R{{captureMandateDetails.amount}}</strong></span><br>
              <span>STARTING DEDUCTIONS ON: <strong>{{captureMandateDetails.startDateString}}</strong></span>
            </ion-card-content>
            <div class="create-mandate-recording-container">
              <ion-icon class="create-mandate-recording-icon" v-if="!recordingComplete && !recording" :icon="micCircle" @click="startRecording()" />
              <ion-icon class="create-mandate-recording-icon" v-else-if="!recordingComplete && recording" :icon="micOffCircle" @click="endRecording()" />
              <ion-icon class="create-mandate-recording-icon" v-else-if="recordingComplete && !recording && recordingPaused" :icon="playCircle" @click="recordingPlayPause()" />
              <ion-icon class="create-mandate-recording-icon" v-else :icon="stopCircle" @click="recordingPlayPause()" />
              <ion-progress-bar class="create-mandate-recording-progress" :value="recordingProgress" :buffer="recording ? 0 : 1"></ion-progress-bar>
              <span class="create-mandate-recording-time">{{recordingLengthString}}</span>
              <audio v-if="recordingResults && recordingResults.recordDataBase64" id="recordedAudioControl" preload="none">
                <source :src="recordingResults.recordDataBase64" type="audio/wav" />
              </audio>
            </div>
            <div v-if="recordingResults && recordingResults.recordDataBase64" style="padding: 10px;">
              <ion-button shape="round" color="primary" @click="retakeRecording()">
                Retake Consent
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12" class="ion-text-left ion-align-self-center create-mandate-consent-heading-span">
      <span>Please authenticate by taking a photo and thumb print of your client below</span>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="6" class="ion-text-left ion-align-self-center input-container">
      <ion-label class="input-container-label">
        Left Thumb
      </ion-label>
      <ion-card class="upload-container">
        <ion-row class="ion-align-items-center">
          <ion-col size="12" class="ion-text-center ion-align-self-center">
            <ion-card-content>
              <div class="fingerprint-image-container" v-if="leftThumb && leftThumb.processedFingerprintDataUri !== '' && leftThumb.processedFingerprintDataUri !== undefined">
                <ion-img class="fingerprint-image" :src="leftThumb.processedFingerprintDataUri" alt="Left Thumb"></ion-img>
              </div>
              <ion-label v-else>
                nothing uploaded
              </ion-label>
            </ion-card-content>
            <div>
              <ion-button shape="round" color="primary" v-if="leftThumb && leftThumb.processedFingerprintDataUri !== '' && leftThumb.processedFingerprintDataUri !== undefined" @click="takeLeftThumbPhoto()">
                Retake
              </ion-button>
              <ion-button shape="round" color="primary" v-else @click="takeLeftThumbPhoto()">
                Take Photo
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-col>
    <ion-col size="6" class="ion-text-left ion-align-self-center input-container">
      <ion-label class="input-container-label">
        Right Thumb
      </ion-label>
      <ion-card class="upload-container">
        <ion-row class="ion-align-items-center">
          <ion-col class="ion-text-center ion-align-self-center">
            <ion-card-content>
              <div class="fingerprint-image-container" v-if="rightThumb && rightThumb.processedFingerprintDataUri !== '' && rightThumb.processedFingerprintDataUri !== undefined">
                <ion-img class="fingerprint-image" :src="rightThumb.processedFingerprintDataUri" alt="Right Thumb"></ion-img>
              </div>
              <ion-label v-else>
                nothing uploaded
              </ion-label>
            </ion-card-content>
            <div>
              <ion-button shape="round" color="primary" v-if="rightThumb && rightThumb.processedFingerprintDataUri !== '' && rightThumb.processedFingerprintDataUri !== undefined" @click="takeRightThumbPhoto()">
                Retake
              </ion-button>
              <ion-button shape="round" color="primary" v-else @click="takeRightThumbPhoto()">
                Take Photo
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-col>
  </ion-row>
  <ion-row style="margin-top: 15px;">
    <ion-col size="6" class="ion-text-left ion-align-self-center">
      <ion-button shape="round" fill="outline" color="primary" class="create-mandate-btn" @click="reset()">
        Reset
      </ion-button>
    </ion-col>
    <ion-col size="6" class="ion-text-right ion-align-self-center">
      <ion-button shape="round" color="primary" class="create-mandate-btn" @click="nextStep()">
        Continue
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
</template>

<script>
import {
  defineComponent
} from 'vue';
import {
  micCircle,
  micOffCircle,
  playCircle,
  stopCircle
} from 'ionicons/icons';
import {
  VoiceRecorder
} from 'capacitor-voice-recorder';
import eventBus from "../services/events";
import store from "../services/store";
import alertsService from "../services/alerts";

export default defineComponent({
  name: 'CreateMandateClientConsent',
  props: {},
  components: {},
  setup() {
    return {
      micCircle,
      micOffCircle,
      playCircle,
      stopCircle
    }
  },
  data() {
    return {
      captureMandateDetails: undefined,
      captureMandateBiometrics: undefined,
      recordingTimer: undefined,
      recordingComplete: false,
      recording: false,
      recordingPaused: true,
      timerProgress: 0,
      recordingProgress: 0,
      recordingLengthString: '--:--',
      recordingResults: undefined
      // Biometrics Here
    }
  },
  methods: {
    reset() {
      this.recordingTimer = undefined;
      this.recordingComplete = false;
      this.recording = false;
      this.recordingPaused = true;
      this.timerProgress = 0;
      this.recordingProgress = 0;
      this.recordingLengthString = '--:--';
      this.recordingResults = undefined;
      // Biometrics Here
    },
    retakeRecording() {
      this.recordingTimer = undefined;
      this.recordingComplete = false;
      this.recording = false;
      this.recordingPaused = true;
      this.timerProgress = 0;
      this.recordingProgress = 0;
      this.recordingLengthString = '--:--';
      this.recordingResults = undefined;
    },
    async nextStep() {
      if (!(this.recordingResults && this.recordingResults.recordDataBase64)) {
        alertsService.showAlert('Error', 'No voice recording captured.');
        return;
      }
      // Biometrics Here
      // if (this.checkBiometricData(this.leftThumb) === false || this.checkBiometricData(this.rightThumb) === false) {
      //   alertsService.showAlert('Error', 'Please make sure that both thumbs have been captured.');
      //   return;
      // }
      // const leftMatchResults = await this.MatchThumbTemplate(store.state.captureMandateBiometrics.leftWsq, store.state.captureMandateBiometrics.leftTemplate, this.leftThumb.base64EncodedWsqBytes, this.leftThumb.base64EncodedFingerprintTemplate);
      // const rightMatchResults = await this.MatchThumbTemplate(store.state.captureMandateBiometrics.rightWsq, store.state.captureMandateBiometrics.rightTemplate, this.rightThumb.base64EncodedWsqBytes, this.rightThumb.base64EncodedFingerprintTemplate);
      // if (leftMatchResults.isVerified !== true && leftMatchResults.matchScore < 80 && rightMatchResults.isVerified !== true && rightMatchResults.matchScore < 80) {
      //   alertsService.showAlert('Error', 'Please make sure that the thumbs scanned are from the correct party.');
      //   return;
      // }
      store.setCaptureMandateVoiceBiometrics({
        voice: this.recordingResults.recordDataBase64
      });
      eventBus().emitter.emit("create-mandate-next-step", {
        fromStep: 'clientConsent'
      });
    },
    async startRecording() {
      this.recording = true;
      let startRecordingResults = await VoiceRecorder.startRecording().then((result) => result.value).catch((error) => error);
      if (startRecordingResults !== true) {
        switch (startRecordingResults) {
          case 'MISSING_PERMISSION':
            alertsService.showAlert('Error', 'Voice Recording is required to create a mandate. Please allow permission before trying again.');
            this.$router.push('/home');
            break;
          case 'ALREADY_RECORDING':
            alertsService.showAlert('Error', 'Voice Recording in progress.');
            break;
          case 'MICROPHONE_BEING_USED':
            alertsService.showAlert('Error', 'Voice Recording failed. Please try again.');
            break;
          case 'DEVICE_CANNOT_VOICE_RECORD':
            alertsService.showAlert('Error', 'Voice Recording is required to create a mandate. Please allow permission before trying again.');
            this.$router.push('/home');
            break;
          case 'FAILED_TO_RECORD':
            alertsService.showAlert('Error', 'Voice Recording failed. Please try again.');
            break;
          default:
            break;
        }
        return;
      }
    },
    async endRecording() {
      this.recordingComplete = true;
      this.recording = false;
      let stopRecordingResults = await VoiceRecorder.stopRecording().then((result) => result.value).catch((error) => error);
      if (stopRecordingResults === 'RECORDING_HAS_NOT_STARTED' || stopRecordingResults === 'FAILED_TO_FETCH_RECORDING') {
        alertsService.showAlert('Error', 'Voice Recording failed. Please try again.');
        return;
      } else {
        this.recordingResults = stopRecordingResults;
        this.recordingLengthString = this.msToTime(stopRecordingResults.msDuration);
      }
    },
    recordingPlayPause() {
      this.recordingPaused = !this.recordingPaused;
      let recordedAudioControl = document.getElementById('recordedAudioControl');
      let method = this.recordingPaused ? 'pause' : 'play';
      recordedAudioControl[method]();
      if (method === 'play') {
        this.timerProgress = this.recordingResults.msDuration;
        this.recordingTimer = setInterval(() => {
          let progressPercentage = 1 - (this.timerProgress / this.recordingResults.msDuration);
          this.recordingProgress = progressPercentage < 0 ? 0 : progressPercentage;
          this.timerProgress -= 200;
          if (this.timerProgress < 0 || this.recordingPaused === true) {
            clearInterval(this.recordingTimer);
            this.recordingPaused = true;
            this.recordingProgress = 0;
          }
        }, 200)
      } else {
        clearInterval(this.recordingTimer);
        this.recordingProgress = 0;
      }
    },
    msToTime(ms) {
      let tempSecs = (ms - (ms % 1000)) / 1000;
      let mins = Math.floor(tempSecs / 60);
      let secs = tempSecs - (mins * 60)
      let result = (mins < 10 ? '0' + mins : mins) + ':' + (secs < 10 ? '0' + secs : secs);
      return result;
    },
    async takeLeftThumbPhoto() {
      // Biometrics Here
    },
    async takeRightThumbPhoto() {
      // Biometrics Here
    },
    // eslint-disable-next-line no-unused-vars
    async MatchThumbTemplate() {
      // Biometrics Here
    },
    checkBiometricData() {
      // Biometrics Here
    },
  },
  mounted() {
    this.captureMandateDetails = store.state.captureMandateDetails;
    this.captureMandateBiometrics = store.state.captureMandateBiometrics;
  }
});
</script>

<style scoped>

</style>
