import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue'
import LoginPage from '../views/LoginPage.vue'
import Register from '../views/Register.vue'
import PasswordResetPage from '../views/PasswordResetPage.vue'
import CreateMandate from '../views/CreateMandate.vue'
import MandateResults from '../views/MandateResults.vue'

import store from "../services/store";

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/home',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: Register
  },
  {
    path: '/passwordreset',
    name: 'PasswordReset',
    component: PasswordResetPage
  },
  {
    path: '/mandate/createmandate',
    name: 'CreateMandate',
    component: CreateMandate
  },
  {
    path: '/mandate/mandateresults/',
    name: 'MandateResults',
    component: MandateResults,
    children: [
      {
        path: '',
        redirect: '/mandate/mandateresults/pending',
      },
      {
        path: 'pending',
        name: 'PendingTab',
        component: () => import('../components/MandateResultsPendingTab.vue')
      },
      {
        path: 'successful',
        name: 'SuccessfulTab',
        component: () => import('../components/MandateResultsSuccessfulTab.vue')
      },
      {
        path: 'failed',
        name: 'FailedTab',
        component: () => import('../components/MandateResultsFailedTab.vue')
      },
   
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if (to.name == 'RegisterPage' && from.name == 'Home' ) {
    next(false)
  }
  else next()

  if( store.state.isAuthenticated === false ) {
    next(false)
  }
  else next()
})

export default router
