<template>
<ion-grid class="ion-no-padding">
  <ion-row class="ion-align-items-center">
    <ion-col size="12" class="ion-padding-start ion-text-left ion-align-self-center">
      <div class="login-page-form-subtitle-div">
        <span>
          {{ subTitle }}
        </span>
      </div>
      <div class="login-page-form-subsubtitle-div">
        <span>
          {{ subSubTitle }}
        </span>
      </div>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col size="12" class="ion-text-center ion-align-self-center">
      <ion-list lines="none" style="padding-bottom: 40px;">
        <ion-item id="rounded" class="input-container">
          <ion-label position="stacked" class="input-container-label">OTP</ion-label>
          <ion-input v-model="resetOTP" type="text" class="input-container-input"></ion-input>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col size="12" class="ion-text-center ion-align-self-center">
      <ion-button @click="submitOTP()" shape="round" color="primary" class="btn-Primary">
        Submit
      </ion-button>
      <div class="login-page-form-text-div">
        <span>
          <a v-if="!otpResendDisabled" @click="resendOTP()">Resend OTP{{otpTimeoutText}}</a>
          <p v-if="otpResendDisabled">Resend OTP{{otpTimeoutText}}</p>
        </span>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
</template>

<script>
import {
  defineComponent
} from 'vue';
import axios from 'axios';
import eventBus from "../services/events";
import alertsService from "../services/alerts";
import store from "../services/store";
import {
  loadingController
} from "@ionic/vue";
import {
  environment
} from '../environments/environments';

export default defineComponent({
  name: 'PasswordResetOTPForm',
  props: {
    subTitle: undefined,
    subSubTitle: undefined
  },
  components: {

  },
  data() {
    return {
      otpConfirmed: false,
      resetOTP: '',
      otpResendDisabled: false,
      otpTimeoutRemain: 30,
      otpTimeoutText: '',
      otpTimeoutTimer: undefined
    }
  },
  methods: {
    async resendOTP() {
      const passwordTokenResults = await this.getPasswordToken(store.state.passwordResetObject.idNumber);
      if (passwordTokenResults && passwordTokenResults.statusCode && (passwordTokenResults.statusCode === '200' || passwordTokenResults.statusCode === 200)) {
        this.otpResendDisabled = true;
        this.otpTimeoutText = ' (' + this.otpTimeoutRemain + ')';
        this.otpTimeoutTimer = setInterval(() => {
          this.otpTimeoutRemain--;
          this.otpTimeoutText = ' (' + this.otpTimeoutRemain + ')';
          if (this.otpTimeoutRemain < 0) {
            clearInterval(this.otpTimeoutTimer);
            this.otpTimeoutText = '';
            this.otpTimeoutRemain = 30;
            this.otpResendDisabled = false;
          }
        }, 1000);
      } else if (passwordTokenResults && passwordTokenResults.message) {
        alertsService.showAlert('Error', passwordTokenResults.message);
      } else {
        alertsService.showAlert('Error', 'An error has occurred, please try again or contact us.');
      }
    },
    submitOTP() {
      if (this.resetOTP !== '' && this.resetOTP !== undefined) {
        store.setPasswordResetToken(this.resetOTP);
        eventBus().emitter.emit("password-reset-otp-confirmed", {});
      } else {
        alertsService.showAlert('Error', 'Invalid OTP entered.');
      }
    },
    async getPasswordToken(idNumber) {
      const loading = await loadingController.create({
        message: 'Loading, Please Wait',
      });
      await loading.present();
      return new Promise((resolve) => {
        const headers = {
          "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
        }
        const data = {
          idNumber: parseInt(idNumber)
        }
        axios.defaults.timeout = 30000
        axios.post(environment.justsolveAPIM + '/getPasswordToken', data, {
          headers
        }).then((response) => {
          loadingController.dismiss();
          resolve(response.data);
        }).catch(() => {
          loadingController.dismiss();
          resolve(false);
        })
      });
    }
  },
  mounted() {}
});
</script>

<style scoped>

</style>
