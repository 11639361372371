<template>
<ion-grid class="ion-no-padding">
  <ion-row class="ion-align-items-center">
    <ion-col size="12" class="ion-padding-start ion-text-left ion-align-self-center">
      <div class="login-page-form-subtitle-div">
        <span>
          {{ subTitle }}
        </span>
      </div>
      <div class="login-page-form-subsubtitle-div">
        <span>
          {{ subSubTitle }}
        </span>
      </div>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col size="12" class="ion-text-center ion-align-self-center">
      <ion-list lines="none" style="padding-bottom: 40px;">
        <ion-item id="rounded" class="input-container">
          <ion-label position="stacked" class="input-container-label">Username</ion-label>
          <ion-input v-model="username" placeholder="Your Username" type="text" class="input-container-input"></ion-input>
        </ion-item>
        <ion-item id="rounded" class="input-container">
          <ion-label position="stacked" class="input-container-label">Password</ion-label>
          <ion-input v-model="password" placeholder="Your Password" type="password" class="input-container-input"></ion-input>
        </ion-item>
      </ion-list>
    </ion-col>
  </ion-row>
  <ion-row class="ion-align-items-center">
    <ion-col size="12" class="ion-text-center ion-align-self-center">
      <ion-button @click="appLogin()" shape="round" color="primary" class="btn-Primary">
        Login
      </ion-button>
      <ion-button @click="toRegister()" shape="round" color="primary" class="btn-Primary">
        Register
      </ion-button>
      <div class="login-page-form-text-div">
        <span>
          Forgot Password? <a @click="toForgotPassword()">Reset here</a>
        </span>
      </div>
    </ion-col>
  </ion-row>
</ion-grid>
</template>

<script>
import { defineComponent } from 'vue';
import validationService from '@/services/validation';

export default defineComponent({
  name: 'LoginForm',
  props: {
    subTitle: undefined,
    subSubTitle: undefined
  },
  components: {

  },
  data() {
    return {
      username: undefined,
      password: undefined
    }
  },
  methods: {
    async appLogin() {
      validationService.attemptLogin(this.username, this.password)
    },
    toRegister() {
      this.$router.push('/register');
    },
    toForgotPassword() {
      this.$router.push('/passwordreset');
    },
  },
  mounted() {},
  //goBack() {
  //  this.$router.push('/');
  //},
});
</script>

<style scoped>

</style>
