<template>
<div class="page-header-block-steps">
  <span class="page-header-block-steps-step">Step 4/4</span>
  <span class="page-header-block-steps-text">Generate Mandate</span>
</div>
<ion-grid class="main-content" style="padding: 15px;">
  <ion-row>
    <ion-col size="12" class="ion-text-left ion-align-self-center">
      <ion-card class="generate-container">
        <ion-row class="ion-align-items-center">
          <ion-col class="ion-align-self-center">
            <ion-card-header>
              <span class="create-mandate-generate-heading-span">Client Identity</span>
            </ion-card-header>
            <ion-card-content v-if="captureMandateBiometrics && captureMandateBiometrics.idNumber">
              <div class="create-mandate-generate-item-container">
                <ion-label>Client ID Number:</ion-label>
                <span class="create-mandate-generate-item-text">{{captureMandateBiometrics.idNumber}}</span>
              </div>
              <div class="create-mandate-generate-item-container">
                <ion-label>Client Portrait:</ion-label>
                <span class="create-mandate-generate-item-text">Uploaded</span>
              </div>
              <div class="create-mandate-generate-item-container">
                <ion-label>Client Left Thumb:</ion-label>
                <span class="create-mandate-generate-item-text">Uploaded</span>
              </div>
              <div class="create-mandate-generate-item-container">
                <ion-label>Client Right Thumb:</ion-label>
                <span class="create-mandate-generate-item-text">Uploaded</span>
              </div>
            </ion-card-content>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12" class="ion-text-left ion-align-self-center">
      <ion-card class="generate-container">
        <ion-row class="ion-align-items-center">
          <ion-col class="ion-align-self-center">
            <ion-card-header>
              <span class="create-mandate-generate-heading-span">Mandate Information</span>
            </ion-card-header>
            <ion-card-content v-if="captureMandateDetails && captureMandateDetails.firstName">
              <div class="create-mandate-generate-item-container">
                <ion-label>Policy Reference Number:</ion-label>
                <span class="create-mandate-generate-item-text">{{captureMandateDetails.reference}}</span>
              </div>
              <div class="create-mandate-generate-item-container">
                <ion-label>Policy Amount:</ion-label>
                <span class="create-mandate-generate-item-text">R{{captureMandateDetails.amount}}</span>
              </div>
              <div class="create-mandate-generate-item-container">
                <ion-label>Policy Start Date:</ion-label>
                <span class="create-mandate-generate-item-text">{{captureMandateDetails.startDateShortString}}</span>
              </div>
            </ion-card-content>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-col>
  </ion-row>
  <ion-row>
    <ion-col size="12" class="ion-text-left ion-align-self-center">
      <ion-card class="generate-container">
        <ion-row class="ion-align-items-center">
          <ion-col class="ion-align-self-center">
            <ion-card-header>
              <span class="create-mandate-generate-heading-span">Client Consent</span>
            </ion-card-header>
            <ion-card-content v-if="captureMandateBiometrics && captureMandateBiometrics.idNumber">
              <div class="create-mandate-generate-item-container">
                <ion-label>Consent Voice:</ion-label>
                <span class="create-mandate-generate-item-text">Uploaded</span>
              </div>
              <div class="create-mandate-generate-item-container">
                <ion-label>Consent Portrait:</ion-label>
                <span class="create-mandate-generate-item-text">Uploaded</span>
              </div>
              <div class="create-mandate-generate-item-container">
                <ion-label>Consent Left Thumb:</ion-label>
                <span class="create-mandate-generate-item-text">Uploaded</span>
              </div>
              <div class="create-mandate-generate-item-container">
                <ion-label>Consent Right Thumb:</ion-label>
                <span class="create-mandate-generate-item-text">Uploaded</span>
              </div>
            </ion-card-content>
          </ion-col>
        </ion-row>
      </ion-card>
    </ion-col>
  </ion-row>
  <ion-row style="margin-top: 15px;">
    <ion-col size="6" class="ion-text-left ion-align-self-center">
      <ion-button shape="round" fill="outline" color="primary" class="create-mandate-btn" @click="restartProcess()">
        Restart
      </ion-button>
    </ion-col>
    <ion-col size="6" class="ion-text-right ion-align-self-center">
      <ion-button shape="round" color="primary" class="create-mandate-btn" @click="submit()">
        Submit
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
</template>

<script>
import {
  defineComponent
} from 'vue';
import store from "../services/store";
import alertsService from "../services/alerts";
import eventBus from "../services/events";
import storageService from '@/services/storage';
import {
  Network
} from '@capacitor/network';
import {
  loadingController
} from '@ionic/vue';
import {
  environment
} from '@/environments/environments';
import axios from 'axios';

export default defineComponent({
  name: 'CreateMandateGenerateMandate',
  props: {},
  components: {},
  data() {
    return {
      captureMandateDetails: undefined,
      captureMandateBiometrics: undefined
    }
  },
  methods: {
    async restartProcess() {
      if (await alertsService.showConfirm('Warning', 'Are you sure you want to restart the mandate process?', 'Yes', 'Cancel') === true) {
        store.clearCaptureMandate();
        eventBus().emitter.emit("create-mandate-next-step", {
          fromStep: 'generateMandate'
        });
      }
    },
    async submit() {
      const userAuth = await storageService.getStorage('userAuth', true);
      if (userAuth === undefined) {
        this.$router.push('/login');
        alertsService.showAlert('Error', 'Please log in and try again.');
        return;
      }
      const status = await Network.getStatus();
      if (status.connected === false) {
        await this.captureMandateOffline();
        alertsService.showAlert('Warning', 'Your device is offline, the mandate will be automatically submitted once the device has internet connectivity.');
      } else {
        const createMandateResults = await this.captureMandate(userAuth.tokenType, userAuth.accessToken);
        if (createMandateResults && (createMandateResults.statusCode === 200 || createMandateResults.statusCode === '200')) {
          if (createMandateResults.message) {
            alertsService.showAlert('Success', createMandateResults.message);
          } else {
            alertsService.showAlert('Success', 'Mandate queued for processing.');
          }
        } else if (createMandateResults && createMandateResults.message) {
          alertsService.showAlert('Error', createMandateResults.message);
          return;
        } else {
          alertsService.showAlert('Error', 'An error has occurred, please try again or contact us.');
          return;
        }
      }
      this.$router.push('/home');
    },
    async captureMandate(tokenType, accessToken) {
      const loading = await loadingController.create({
        message: 'Loading, Please Wait',
      });
      await loading.present();
      return new Promise((resolve) => {
        const headers = {
          "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
        }
        const data = {
          tokenType: tokenType,
          accessToken: accessToken,
          mandate: {
            insurer: this.captureMandateDetails.insurer,
            amount: this.captureMandateDetails.amount * 100,
            reference: this.captureMandateDetails.reference,
            startDate: this.captureMandateDetails.startDate,
            surname: this.captureMandateDetails.surname,
            initials: this.captureMandateDetails.initials
          },
          biometrics: {
            deviceId: this.captureMandateBiometrics.deviceId,
            idNumber: this.captureMandateBiometrics.idNumber,
            leftTemplate: this.captureMandateBiometrics.leftTemplate,
            leftWsq: this.captureMandateBiometrics.leftWsq,
            rightTemplate: this.captureMandateBiometrics.rightTemplate,
            rightWsq: this.captureMandateBiometrics.rightWsq,
            face: this.captureMandateBiometrics.face,
            voice: this.captureMandateBiometrics.voice
          }
        }
        axios.defaults.timeout = 30000
        axios.post(environment.justsolveAPIM + '/createMandate', data, {
          headers
        }).then((response) => {
          loadingController.dismiss();
          resolve(response.data);
        }).catch(() => {
          loadingController.dismiss();
          resolve(false);
        })
      });
    },
    async captureMandateOffline() {
      const loading = await loadingController.create({
        message: 'Loading, Please Wait',
      });
      await loading.present();
      const pendingMandatePayload = {
        mandate: {
          insurer: this.captureMandateDetails.insurer,
          amount: this.captureMandateDetails.amount * 100,
          reference: this.captureMandateDetails.reference,
          startDate: this.captureMandateDetails.startDate,
          surname: this.captureMandateDetails.surname,
          initials: this.captureMandateDetails.initials
        },
        biometrics: {
          deviceId: this.captureMandateBiometrics.deviceId,
          idNumber: this.captureMandateBiometrics.idNumber,
          leftTemplate: this.captureMandateBiometrics.leftTemplate,
          leftWsq: this.captureMandateBiometrics.leftWsq,
          rightTemplate: this.captureMandateBiometrics.rightTemplate,
          rightWsq: this.captureMandateBiometrics.rightWsq,
          face: this.captureMandateBiometrics.face,
          voice: this.captureMandateBiometrics.voice
        },
        sent: false
      }
      let pendingOfflineMandates = await storageService.getStorage('pendingOfflineMandates', true);
      if (pendingOfflineMandates && pendingOfflineMandates.length > 0) {
        pendingOfflineMandates.push(pendingMandatePayload);
      } else {
        pendingOfflineMandates = [];
        pendingOfflineMandates.push(pendingMandatePayload);
      }
      await storageService.setStorage('pendingOfflineMandates', pendingOfflineMandates, true);
      loadingController.dismiss();
    }
  },
  mounted() {
    this.captureMandateDetails = store.state.captureMandateDetails;
    this.captureMandateBiometrics = store.state.captureMandateBiometrics;
  }
});
</script>

<style scoped>

</style>
