<template>
<ion-page>
  <div class="page-header-block">
    <span>Homescreen</span>
  </div>
  <ion-grid>
    <ion-row class="ion-align-items-center">
      <ion-col size="12" class="ion-text-center ion-align-self-center" v-for="(item, i) in HomeScreenUIComponents" :key="i" v-bind:class="[ item.Active ? 'home-page-card home-page-card-first' : '']">
        <ion-card @click="RouteToPage(item.Id)" class="home-page-card">
          <ion-card-content>
            <div class="home-page-card-container">
              <ion-icon :icon="item.Icon" />
              <span>{{item.Name}}</span>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-page>
</template>

<script>
import {
  defineComponent
} from 'vue';
import storageService from "../services/storage";
import {
  documentOutline,
  documentsOutline,
  documentTextOutline
} from 'ionicons/icons';
//import store from "../services/store";

export default defineComponent({
  components: {},
  setup() {
    return {
      documentOutline,
      documentsOutline,
      documentTextOutline
    }
  },
  data() {
    return {
      HomeScreenUIComponents: [{
          Id: 1,
          Name: 'Create Mandate',
          Icon: documentOutline,
          Active: false,
          Route: '/createmandate'
        },
        {
          Id: 2,
          Name: 'Mandate Results',
          Icon: documentsOutline,
          Active: false,
          Route: '/mandateresults'
        }//,
        //{
        //  Id: 3,
        //  Name: 'Mandate Manage',
        //  Icon: documentTextOutline,
        //  Active: false,
        //  Route: '/mandatemanage'
        //}
      ]
    }
  },
  ionViewDidEnter() {
    //this.checkToken();
  },
  ionViewDidLeave() {

  },
  ionViewWillEnter() {
    this.checkToken();
  },
  ionViewWillLeave() {

  },
  methods: {
    async checkToken() {
      const userAuth = await storageService.getStorage('userAuth', true);
      const userMe = await storageService.getStorage('userMe', true);
      if (userAuth && userMe === undefined) {
      //if (store.state.isAuthenticated === false || (userAuth && userMe === undefined)) {
      //if (store.state.isAuthenticated === false) {
        this.$router.push('/login');
      }
    },
    RouteToPage(Id) {
      switch (Id) {
        case 1:
          this.HomeScreenUIComponents[0].Active = true
          this.HomeScreenUIComponents[1].Active = false
          //this.HomeScreenUIComponents[2].Active = false

          this.$router.push('/mandate/createmandate')

          break;
        case 2:
          this.HomeScreenUIComponents[0].Active = false
          this.HomeScreenUIComponents[1].Active = true
          //this.HomeScreenUIComponents[2].Active = false

          this.$router.push('/mandate/mandateresults')

        //  break;
        //case 3:
        //  this.HomeScreenUIComponents[0].Active = false
        //  this.HomeScreenUIComponents[1].Active = false
        //  this.HomeScreenUIComponents[2].Active = true

      }
    },
  },
  mounted: async function () {}
});
</script>

<style scoped>
#container {
  text-align: center;

  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;

  color: #8c8c8c;

  margin: 0;
}

#container a {
  text-decoration: none;
}
</style>
