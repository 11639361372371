import { Dialog } from '@capacitor/dialog';

const alertsService = {
  async showAlert (title='Unknown Alert', message='No message received') {
    await Dialog.alert({
      title: title,
      message: message
    });
  },
  
  async showConfirm (title, message, okButtonTitle, cancelButtonTitle) {
    const { value } = await Dialog.confirm({
      title: title,
      message: message,
      okButtonTitle: okButtonTitle,
      cancelButtonTitle: cancelButtonTitle
    });
    return value;
  },
  
  async showPrompt (title, message) {
    const { value, cancelled } = await Dialog.prompt({
      title: title,
      message: message
    });
    if (cancelled === true) {
      return cancelled;
    } else {
      return value;
    }
  }
}

export default alertsService