<template>
<ion-row class="ion-align-items-center">
  <ion-col size="12" class="ion-padding-start ion-text-left ion-align-self-center">
    <div class="login-page-form-subtitle-div">
      <span>
        {{ subTitle }}
      </span>
    </div>
    <div class="login-page-form-subsubtitle-div">
      <span>
        {{ subSubTitle }}
      </span>
    </div>
  </ion-col>
</ion-row>

<div v-if="VerifyID">
  <ion-row class="ion-align-items-center" style="height: 100%">
    <ion-col size="12" class="ion-text-center ion-align-self-center">
      <ion-grid>
        <form>
          <ion-list style="padding-bottom: 40px;">
            <ion-item id="rounded" class="input-container">
              <ion-label position="stacked" class="input-container-label">ID Number</ion-label>
              <ion-input v-model="IdNumber" name="idNumber" :rules="isRequired" placeholder="Your ID Number" class="input-container-input" type="text"></ion-input>
            </ion-item>

            <ion-item id="rounded" class="input-container">
              <ion-label position="stacked" class="input-container-label">Password</ion-label>
              <ion-input v-model="Password" placeholder="Your Password" class="input-container-input" type="password"></ion-input>
            </ion-item>
          </ion-list>

          <ion-row class="ion-align-items-center">
            <ion-col size="12" class="ion-text-center ion-align-self-center">
              <ion-button @click="ContinueIDVerification()" shape="round" color="primary" class="btn-Primary">
                Continue
              </ion-button>
            </ion-col>
          </ion-row>
        </form>
      </ion-grid>
    </ion-col>
  </ion-row>
</div>

<div v-if="LoadWhoYou" id="WhoYouSDKDiv">
  <iframe id="WhoYouSDK" :src="WhoYou" allow="camera;microphone" height="500" @load="iframeLoaded()" @change="iframeLoaded()" allowfullscreen width="100%"></iframe>
</div>

<ion-row v-if="LoadWhoYou" class="ion-align-items-center">
  <ion-col size="12" class="ion-text-center ion-align-self-center">
    <ion-button shape="round" color="primary" class="btn-Primary" @click="Verify()">
      Verify
    </ion-button>
  </ion-col>
</ion-row>

<div v-if="BrokerVerification">
  <div v-if="BrokerVerificationPassword">

    <ion-grid>
      <ion-list style="padding-bottom: 40px;">
        <ion-item id="rounded">
          <ion-label position="stacked">Name</ion-label>
          <ion-input placeholder="Your Name(s)" class="input-container-input" type="text"></ion-input>
        </ion-item>

        <ion-item id="rounded">
          <ion-label position="stacked">Surname</ion-label>
          <ion-input placeholder="Your Surname" class="input-container-input" type="text"></ion-input>
        </ion-item>

        <ion-item id="rounded">
          <ion-label position="stacked">Number</ion-label>
          <ion-input placeholder="Your Cell Phone Number" class="input-container-input" type="text"></ion-input>
        </ion-item>

        <ion-item id="rounded">
          <ion-label position="stacked">Email</ion-label>
          <ion-input placeholder="Your Email Address" class="input-container-input" type="email"></ion-input>
        </ion-item>

      </ion-list>
      <ion-row class="ion-align-items-center">
        <ion-col size="12" class="ion-text-center ion-align-self-center">
          <ion-button shape="round" color="primary" class="btn-Primary">
            Create Password
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>

  <div v-if="BrokerVerificationVerifyAccount">

    <ion-grid>
      <ion-list lines="none">
        <ion-item>
          <ion-label style="font-weight: bold;" position="stacked" class="input-container-label">
            Photo of Broker
          </ion-label>
        </ion-item>
        <ion-card class="upload-container">
          <ion-row class="ion-align-items-center" style="height: 100%">
            <ion-col size="12" class="ion-text-center ion-align-self-center">
              <ion-card-content>
                <div class="fingerprint-image-container" v-if="FacePhoto !== undefined">
                  <ion-img class="full-image" v-bind:src="this.FacePhoto" />
                </div>
                <ion-label v-else>
                  nothing uploaded
                </ion-label>
              </ion-card-content>
              <div>
                <ion-button shape="round" color="primary" v-if="FacePhoto !== undefined" @click="TakeFacePhoto()">
                  Retake
                </ion-button>
                <ion-button shape="round" color="primary" v-else @click="TakeFacePhoto()">
                  Take Photo
                </ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-list>

      <ion-row style="padding-top: 10px;">

        <ion-col size="6">
          <ion-item lines="none">
            <ion-label style="font-weight: bold;" position="stacked" class="input-container-label">
              Left Thumb
            </ion-label>
          </ion-item>
          <ion-card class="upload-container">
            <ion-row class="ion-align-items-center" style="height: 100%">
              <ion-col size="12" class="ion-text-center ion-align-self-center">
                <ion-card-content>
                  <div class="fingerprint-image-container" v-if="leftThumb && leftThumb.processedFingerprintDataUri !== '' && leftThumb.processedFingerprintDataUri !== undefined">
                    <ion-img class="fingerprint-image" :src="leftThumb.processedFingerprintDataUri" alt="Left Thumb"></ion-img>
                  </div>
                  <ion-label v-else>
                    nothing uploaded
                  </ion-label>
                </ion-card-content>
                <div>
                  <ion-button shape="round" color="primary" v-if="leftThumb && leftThumb.processedFingerprintDataUri !== '' && leftThumb.processedFingerprintDataUri !== undefined" @click="TakeLeftThumbPhoto()">
                    Retake
                  </ion-button>
                  <ion-button shape="round" color="primary" v-else @click="TakeLeftThumbPhoto()">
                    Take Photo
                  </ion-button>
                </div>
              </ion-col>
            </ion-row>
          </ion-card>
        </ion-col>

        <ion-col size="6">
          <ion-item lines="none">
            <ion-label style="font-weight: bold;" position="stacked" class="input-container-label">
              Right Thumb
            </ion-label>
          </ion-item>
          <ion-card class="upload-container">
            <ion-row class="ion-align-items-center" style="height: 100%">
              <ion-col class="ion-text-center ion-align-self-center">
                <ion-card-content>
                  <div class="fingerprint-image-container" v-if="rightThumb && rightThumb.processedFingerprintDataUri !== '' && rightThumb.processedFingerprintDataUri !== undefined">
                    <ion-img class="fingerprint-image" :src="rightThumb.processedFingerprintDataUri" alt="Right Thumb"></ion-img>
                  </div>
                  <ion-label v-else>
                    nothing uploaded
                  </ion-label>
                </ion-card-content>
                <div>
                  <ion-button shape="round" color="primary" v-if="rightThumb && rightThumb.processedFingerprintDataUri !== '' && rightThumb.processedFingerprintDataUri !== undefined" @click="TakeRightThumbPhoto()">
                    Retake
                  </ion-button>
                  <ion-button shape="round" color="primary" v-else @click="TakeRightThumbPhoto()">
                    Take Photo
                  </ion-button>
                </div>
              </ion-col>
            </ion-row>
          </ion-card>
        </ion-col>

      </ion-row>

      <ion-row class="ion-align-items-center">
        <ion-col size="12" class="ion-text-center ion-align-self-center">
          <ion-button @click="VerifyAccount()" shape="round" color="primary" class="btn-Primary">
            Verify Account
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>

  </div>
</div>
<ion-loading-controller></ion-loading-controller>
</template>

<script>
import {
  defineComponent
} from 'vue';

import {
  Camera,
  CameraResultType
} from '@capacitor/camera';

import {
  Device
} from '@capacitor/device';

import validationService from "../services/validation";

import alertsService from "../services/alerts";

import {
  Network
} from '@capacitor/network';

import {
  loadingController
} from "@ionic/vue";

import axios from 'axios'
import {
  environment
} from '../environments/environments';

import storageService from "../services/storage";

export default defineComponent({
  name: 'RegisterForm',
  components: {

  },
  data() {
    return {
      /*
      Conditional Rendering
      */
      VerifyID: true,
      BrokerVerification: false,
      BrokerVerificationPassword: false,
      BrokerVerificationVerifyAccount: false,

      NoFaceImage: true,
      FaceImage: false,

      NoLeftThumb: true,
      LeftThumb: false,

      NoRightThumb: true,
      RightThumb: false,

      /*
      Data Models
      */
      IdNumber: undefined,
      Name: undefined,
      Surname: undefined,
      CellNumber: undefined,
      Email: undefined,
      Password: undefined,

      DeviceId: undefined,

      FacePhoto: undefined,
      FacePhotoString: "",

      LeftThumbPhoto: undefined,
      LeftThumbPhotoString: undefined,

      RightThumbPhoto: undefined,
      RightThumbPhotoString: undefined,

      subTitle: "Register",
      subSubTitle: "Please enter your ID number to see if you are registered",

      WhoYou: undefined,
      PinCode: undefined,

      // Biometrics Here

      /*
      Timer
      */
      TimerCountdown: 3600
    }
  },
  mounted: async function () {
    /*
            const info = await Device.getInfo();

            const DeviceID = await Device.getId()

            this.getNetworkConnection()
    */
    window.addEventListener('message', this.receiveMessage)
  },
  beforeUnmount: function () {
    window.removeEventListener('message', this.receiveMessage)

  },
  computed: {

  },

  watch() {
    this.Timer()
  },
  created() {

  },
  methods: {
    iframeLoaded() {},

    onLoading() {},
    /*
    ***************************************
    ***************************************
          Validation Functions
    ***************************************
    ***************************************
    */

    /*
     ***************************************
     ***************************************
     ***************************************
     ***************************************
     ***************************************
     */

    Timer() {
      if (this.TimerCountdown > 0) {
        setTimeout(() => {
          this.TimerCountdown -= 1
          this.Timer()
        }, 1000)
      }

      if (this.TimerCountdown < 30) {
        this.VerifyID = true
        this.BrokerVerification = false
        this.BrokerVerificationVerifyAccount = false
      }
    },

    async getNetworkConnection() {

      const status = await Network.getStatus();

      if (status.connected == false) {
        alertsService.showAlert('Error', 'Internet Connectivity Required to continue')
      }

    },
    async ContinueIDVerification() {

      if (this.IdNumber === undefined && this.Password === undefined) {

        alertsService.showAlert('Error', 'Please Enter a valid ID Number and Password.');

      } else if (this.IdNumber === undefined || this.IdNumber === "") {

        alertsService.showAlert('Error', 'Please Enter a valid ID Number.');

      } else if (this.Password === undefined || this.Password === "") {

        alertsService.showAlert('Error', 'Please Enter a Valid Password.');

      } else {
        const loading = await loadingController.create({
          message: 'Loading Please Wait',

        });
        await loading.present();

        const DeviceID = await Device.getId()

        const headers = {
          "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
        }

        const data = {
          idNumber: this.IdNumber,
          password: this.Password,
          deviceId: DeviceID.uuid

        }
        axios.post((environment.justsolveAPIM + '/verifybroker'), data, {
          headers
        }).then(async (response) => {

          if (response.data.statusCode == 200) {
            await this.RequestWhoYouPin()
            loadingController.dismiss()

            this.VerifyID = false
            this.LoadWhoYou = true
            //this.BrokerVerification = true
            //this.BrokerVerificationVerifyAccount = true

            this.subTitle = "Broker Verification"
            this.subSubTitle = "Please Activate your account below"

            this.Timer()

          }

          return response.data

        }).catch((error) => {

          loadingController.dismiss()
          validationService.catchError(error, true);
        })

      }

    },

    async RequestWhoYouPin() {
      return new Promise((resolve) => {
        const headers = {
          "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
        }

        const data = {
          IdNumber: this.IdNumber

        }
        axios.post((environment.justsolveAPIM + '/whoYouRequestPin'), data, {
          headers
        }).then((response) => {

          let Pin = response.data.detail.pinCode
          this.PinCode = Pin
          let WhoYouPWAUrl = response.data.detail.url.PWA
          this.WhoYou = WhoYouPWAUrl.concat(Pin)
          resolve(this.WhoYou)

        })
      })
    },

    GetWhoYouRequestResult() {

      return new Promise((resolve) => {

        const headers = {
          "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
        }

        const data = {
          "PinCode": this.PinCode
        }
        axios.post((environment.justsolveAPIM + '/whoYouRequestResult'), data, {
          headers
        }).then((response) => {

          console.log(response.data.detail)
          if (response.data.detail !== undefined) {
            loadingController.dismiss()

          } else if (response.data.detail == undefined) {
            loadingController.dismiss()

          }
          resolve(response.data)
        })
      })

    },

    async Done() {
      const loading = await loadingController.create({
        message: 'Loading Please Wait',

      });
      await loading.present();

      var WhoYouResult = await this.GetWhoYouRequestResult()

      if (WhoYouResult.detail !== undefined) {
        loadingController.dismiss()

        this.FacePhoto = WhoYouResult.detail.photo

        //this.VerifyAccount()

      } else if (WhoYouResult.detail == undefined) {
        loadingController.dismiss()
        alertsService.showAlert('Error', 'Please Capture face photo')

      }

    },

    async TakeFacePhoto() {

      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
      });

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)

      /*
      URL Generated Image
      */
      //var image = image.webPath;

      /*
      DataURL Generated Image
      */
      //console.log(image.dataUrl)

      var du = image.dataUrl

      /*
      Base64 Generated Image
      */
      //console.log(image.base64String)

      var arr = du.split(',')

      this.FacePhotoString = arr[1]
      this.FacePhoto = du

      if (this.FacePhoto != undefined) {
        this.NoFaceImage = false
        this.FaceImage = true
      } else {
        this.NoFaceImage = true
      }
    },

    async TakeLeftThumbPhoto() {
      // Biometrics Here
    },

    async TakeRightThumbPhoto() {
      // Biometrics Here
    },

    async VerifyAccount() {
        const DeviceID = await Device.getId()

        const loading = await loadingController.create({
          message: 'Loading Please Wait',

        });
        await loading.present();

        const headers = {
          "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
        }

        const data = {
          deviceId: DeviceID.uuid,
          idNumber: this.IdNumber,
          // Biometrics Here
          // leftTemplate: this.leftThumb.base64EncodedFingerprintTemplate,
          // leftWsq: this.leftThumb.base64EncodedWsqBytes,
          // rightTemplate: this.rightThumb.base64EncodedFingerprintTemplate,
          // rightWsq: this.rightThumb.base64EncodedWsqBytes,
          leftTemplate: '',
          leftWsq: '',
          rightTemplate: '',
          rightWsq: '',
          face: this.FacePhoto
        }

        // Biometrics Here
        // const biometricTemplate = {
        //   leftTemplate: '',
        //   leftWsq: '',
        //   rightTemplate: '',
        //   rightWsq: ''
        // }

        axios.post((environment.justsolveAPIM + '/registerBroker'), data, {
            headers
          })
          .then(async (response) => {

            if (response.data.statusCode == 200) {

              await this.Me()

              loadingController.dismiss()

              // Store data Locally //

              storageService.setStorage('userAuth', response.data.data, true);

              // Biometrics Here
              // storageService.setStorage('biometricTemplate', biometricTemplate, true)

              // Set images to nothing when successful //
              this.FacePhoto = undefined
              
              this.rightThumb = undefined
              this.leftThumb = undefined

              // Render to correct steps //

              this.VerifyID = true
              this.BrokerVerification = false
              this.BrokerVerificationVerifyAccount = false

              this.$router.push('/home');
            }
            return response.data

          }).catch((error) => {

            loadingController.dismiss();
            validationService.catchError(error, true);
            //Handle specifc error here.
            if (error.response.status == 404) {
              this.VerifyID = true
              this.BrokerVerification = false
              this.BrokerVerificationVerifyAccount = false
            }
            return error
          })
      

      this.$router.push('/home');

    },

    Me(tokenType, accessToken) {
      return new Promise((resolve) => {
        const headers = {
          "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
        }
        const data = {
          TokenType: tokenType,
          Token: accessToken
        }

        axios.post(environment.justsolveAPIM + '/me', data, {
          headers
        }).then(async (response) => {
          await storageService.setStorage('userMe', response.data.data, true);
          return response
        })

        resolve(true)
      })
    }
  },

});
</script>

<style lang="scss" scoped>

</style>
