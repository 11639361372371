import storageService from '@/services/storage';
import alertsService from "./alerts"
import { loadingController } from "@ionic/vue";
import { BiometryType, NativeBiometric } from "capacitor-native-biometric";
import { Device } from '@capacitor/device';
import { environment } from '@/environments/environments';
import store from "./store"
import eventBus from '@/services/events';
import axios from 'axios';

const validationService = {
    async validateToken () {
        const userAuth = await storageService.getStorage('userAuth', true);
        if (userAuth === undefined) {
            window.location = '/'
            //this.$router.push('/');
            alertsService.showAlert('Error', 'Please log in and try again.');
            return;
        }
        //We are logged in. Check Expiry of the Token

        let unix_timestamp = parseInt(userAuth.expires);
        // Create a new JavaScript Date object based on the timestamp
        // multiplied by 1000 so that the argument is in milliseconds, not seconds.
        var expiryDate = new Date(unix_timestamp * 1000);
        var today = new Date();
        if(expiryDate <= today) {
            // Then we need to refresh the token now.
            var refreshResults = await this.refreshToken(userAuth.accessToken);
            if (refreshResults) {
                if (refreshResults.statusCode === 200 || refreshResults.statusCode === '200') {
                    storageService.setStorage('userAuth', refreshResults.data, true);
                    window.location = '/home'
                    //this.$router.push('/home');
                } else if (refreshResults.message) {
                    alertsService.showAlert('Error', refreshResults.message);
                } else {
                    alertsService.showAlert('Error', 'An error has occurred, please try again or contact us.');
                }
            } else {
                alertsService.showAlert('Error', 'An error has occurred, please try again or contact us.');
            }
        }
    },

    async refreshToken(currentToken) {
        const loading = await loadingController.create({
          message: 'Loading, Please Wait',
        });
        await loading.present();
        return new Promise((resolve) => {
          const headers = {
            "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
          }
          const data = {
            refreshToken: currentToken
          }
          axios.defaults.timeout = 30000
          axios.post(environment.justsolveAPIM + '/refreshToken', data, {
            headers
          }).then(async (response) => {
            await this.Me(response.data.data.tokenType, response.data.data.accessToken)
            eventBus().emitter.emit("device-on-connect", {});
            loadingController.dismiss();
            store.setIsAuthenticated(true)
            resolve(response.data);
          }).catch(() => {
            loadingController.dismiss();
            resolve(false);
          })
        });
    },

    async catchError(error, mustShowAlert=true) {
        //Bad Request
        if (error.response.status == 400) {
            loadingController.dismiss()
            if (mustShowAlert){ alertsService.showAlert('Error', 'Please Try Again') }

        //Unauthorized
        } else if (error.response.status == 401) {
            loadingController.dismiss()
            if (mustShowAlert){ alertsService.showAlert('Error', 'Please Try Again') }

        //403 Forbidden
        } else if (error.response.status == 403) {
            loadingController.dismiss()
            if (mustShowAlert){ alertsService.showAlert('Error', 'Please Try Again') }

        //Not Found
        } else if (error.response.status == 404) {
            loadingController.dismiss()
            if (mustShowAlert){ alertsService.showAlert('Error', 'Please Try Again') }
            //alertsService.showAlert('Error', 'ID number not registered please contact support@qlink.co.za')

        //Not Acceptable
        } else if (error.response.status == 406) {
            loadingController.dismiss()
            if (mustShowAlert){ alertsService.showAlert('Error', 'Please Try Again') }

        //Unprocessable Entity
        } else if (error.response.status == 422) {
            loadingController.dismiss()
            if (mustShowAlert){ alertsService.showAlert('Error', 'Please Try Again') }

        //Internal Server Error
        } else if (error.response.status == 500) {
            loadingController.dismiss()
            if (mustShowAlert){ alertsService.showAlert('Error', 'Please Try Again') }
        }   
    },

//##############################################################################################################
    async attemptLogin(validateUsername, validatePassword) {
        let loginResults = undefined;
        if (validateUsername && validatePassword) {
            const deviceId = await Device.getId();
            loginResults = await validationService.getToken(validateUsername, validatePassword, deviceId.uuid);
        } else {
            store.setIsAuthenticated(false)
            alertsService.showAlert('Error', 'Please enter a valid username & password.');
            return;
        }
        if (loginResults) {
            if (loginResults.statusCode === 200 || loginResults.statusCode === '200') {
                await storageService.setStorage('userAuth', loginResults.data, true);
                store.setIsAuthenticated(true)
                if (store.state.isCredentialLogin === false){
                    this.setCredential(validateUsername, validatePassword)
                }
                //TGJ Not sure about this but it works.
                window.location = '/home'
                //this.$router.push('/home');
            } else if (loginResults.message) {
                store.setIsAuthenticated(false)
                alertsService.showAlert('Error', loginResults.message);
            } else {
                store.setIsAuthenticated(false)
                alertsService.showAlert('Error', 'An error has occurred, please try again or contact us.');
            }
        } else {
            store.setIsAuthenticated(false)
            alertsService.showAlert('Error', 'An error has occurred, please try again or contact us.');
        }
    },

    async getToken(username, password, deviceId) {
        const loading = await loadingController.create({
            message: 'Loading, Please Wait',
        });
        await loading.present();
        return new Promise((resolve) => {
            const headers = {
                "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
            }
            const data = {
                idNumber: parseInt(username),
                password: password,
                deviceId: deviceId
            }
            axios.defaults.timeout = 30000
            axios.post(environment.justsolveAPIM + '/getToken', data, {
                headers
            }).then(async (response) => {
                var validatedMe = await this.validateMe(response.data.data.tokenType, response.data.data.accessToken)
                if (validatedMe){
                    eventBus().emitter.emit("device-on-connect", {});
                    loadingController.dismiss();
                    store.setIsAuthenticated(true)
                    resolve(response.data);
                } else {
                    loadingController.dismiss();
                    resolve(false);
                }

            }).catch(() => {
                loadingController.dismiss();
                resolve(false);
            })
        });
    },

    validateMe(tokenType, accessToken) {
        return new Promise((resolve) => {
            const headers = {
                "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
            }
            const data = {
                TokenType: tokenType,
                Token: accessToken
            }
    
            axios.post(environment.justsolveAPIM + '/me', data, {
                headers
            }).then(async (response) => {
                await storageService.setStorage('userMe', response.data.data, true);
                resolve(true)
                return response.data.data
            }).catch(() => {
                resolve(false);
            })
          })
        },

//Biometric validations #############################################################################################
    async isBiometricAvailable() {
        // Check if biometrics are available and which type is supported
        if (NativeBiometric.isAvailable() && NativeBiometric.BiometryType == BiometryType.FINGERPRINT) {
            return true;
        }
        return false;
    },

    async hasBiometricCredentials() {
        // Check if biometrics are available and which type is supported
        var credentials = await NativeBiometric.getCredentials({ 
            server: "Regulation 26A Mandates",
        })
        if (credentials && credentials.username && credentials.password) {
            return true;
        }
        return false;
    },

    async setCredential(newUsername, newPassword) {
        if (this.isBiometricAvailable()) {
            await NativeBiometric.setCredentials({
                username: newUsername,
                password: newPassword,
                server: 'Regulation 26A Mandates',
            });
            return true;
        } else {
            return false;
        }
    },

    async deleteCredential() {
        // Delete user's credentials
        if (this.isBiometricAvailable()) {
            var deletedCredentials = await NativeBiometric.deleteCredentials({
                server: 'Regulation 26A Mandates',
            });
            if (deletedCredentials){
                return true;
            }
            return false;
        }
        return false;
    },
    
}
 
export default validationService

//    async getBiometricLogin() {
//        if (!this.isBiometricAvailable()){
//            return false; // we will need to review this closer to completion.
//        }
//        //Biometric available
//        NativeBiometric.getCredentials({
//            server: "Regulation 26A Mandates",
//        })
//        // Authenticate using biometrics before logging the user in
//        NativeBiometric.verifyIdentity({
//            reason: "For easy log in",
//            title: "Biometric Log in",
//            subtitle: "Subtitle here?",
//            description: "Description here?",
//        }).then(
//            () => {
//                // Authentication successful
//                return true; // we will need to review this closer to completion.
//            },
//            (error) => {
//                // Failed to authenticate
//            });
//        });
//    },

