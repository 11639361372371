<template>
<div class="page-header-block">
  <span>Biometric Consent</span>
</div>
<ion-grid class="main-content" style="padding: 15px;">
  <ion-row class="ion-align-items-center" v-for="(item, i) in ConsentUIComponents" :key="i">
    <ion-col size="12" class="ion-text-center ion-align-self-center">
      <ion-card class="create-mandate-consent-card">
        <ion-card-content>
          <ion-grid>
            <ion-row>
              <ion-col size="3">
                <div class="create-mandate-consent-icon-container">
                  <ion-icon :icon="item.icon" />
                </div>
              </ion-col>
              <ion-col size="9">
                <div class="create-mandate-consent-text-container">
                  <span class="create-mandate-consent-title" :class="item.titleClass">{{item.title}}</span>
                </div>
                <div class="create-mandate-consent-text-container">
                  <span class="create-mandate-consent-text">{{item.text}}</span>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
  <ion-row style="margin-top: 15px;">
    <ion-col size="6" class="ion-text-left ion-align-self-center">
      <ion-button shape="round" fill="outline" color="primary" class="create-mandate-btn" @click="goBack()">
        Back
      </ion-button>
    </ion-col>
    <ion-col size="6" class="ion-text-right ion-align-self-center">
      <ion-button shape="round" color="primary" class="create-mandate-btn" @click="nextStep()">
        Start
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
</template>

<script>
import {
  defineComponent
} from 'vue';
import {
  fingerPrintOutline,
  documentTextOutline,
  checkmarkCircle,
  downloadOutline
} from 'ionicons/icons';
import eventBus from "../services/events";

export default defineComponent({
  name: 'CreateMandateBiometricConsent',
  props: {},
  components: {},
  setup() {
    return {
      fingerPrintOutline,
      documentTextOutline,
      checkmarkCircle,
      downloadOutline
    }
  },
  data() {
    return {
      ConsentUIComponents: [{
        title: 'Step 1: Capture Client Identity',
        text: 'Using fingerprints, facial recognition and a South African ID number, we will capture your client information.',
        icon: fingerPrintOutline,
        titleClass: 'text-color-secondary'
      }, {
        title: 'Step 2: Capture Mandate',
        text: 'Capture important information regarding the mandate. Important here are the Amount, Deduction Start Date and the Policy Reference Number.',
        icon: documentTextOutline,
        titleClass: 'text-color-warning'
      }, {
        title: 'Step 3: Client Consent',
        text: 'The client will digitally sign the mandate using either their voice, fingerprints or a photograph.',
        icon: checkmarkCircle,
        titleClass: 'text-color-danger'
      }, {
        title: 'Step 4: Generate Mandate',
        text: 'All the collected information will be sent for verification. If successful, a new mandate will be generated.',
        icon: downloadOutline,
        titleClass: 'text-color-primary'
      }, ]
    }
  },
  methods: {
    goBack() {
      this.$router.push('/home');
    },
    nextStep() {
      eventBus().emitter.emit("create-mandate-next-step", {
        fromStep: 'biometricConsent'
      });
    }
  },
  mounted() {}
});
</script>

<style scoped>

</style>
