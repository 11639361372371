<template>
<div class="page-header-block-steps">
  <span class="page-header-block-steps-step">Step 1/4</span>
  <span class="page-header-block-steps-text">Capture Client Identity</span>
</div>
<ion-grid class="main-content" style="padding: 15px;">
  <form>
    <ion-row>
      <ion-col size="12" class="ion-text-left ion-align-self-center input-container">
        <ion-label position="stacked" class="input-container-label">Are you appointed Power of Attorney on behalf of the Grant Recipient?</ion-label>
      </ion-col>
      <ion-col size="12" class="ion-text-left ion-align-self-center input-container">
        <ion-button :disabled="powerOfAttorney" color="primary" class="input-container-btn" @click="togglePowerOfAttorney(true)">
          Yes
        </ion-button>
        <ion-button :disabled="!powerOfAttorney" color="primary" class="input-container-btn" @click="togglePowerOfAttorney(false)">
          No
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-text-left ion-align-self-center input-container">
        <ion-label position="stacked" class="input-container-label">First Name</ion-label>
        <ion-input v-model="firstName" placeholder="Name" type="text" class="input-container-input"></ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-text-left ion-align-self-center input-container">
        <ion-label position="stacked" class="input-container-label">Last Name</ion-label>
        <ion-input v-model="surname" placeholder="Surname" type="text" class="input-container-input"></ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-text-left ion-align-self-center input-container">
        <ion-label position="stacked" class="input-container-label">Identity Number</ion-label>
        <ion-input v-model="idNumber" placeholder="ID Number" type="number" class="input-container-input"></ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-text-left ion-align-self-center input-container">
        <ion-label position="stacked" class="input-container-label">Cell Number</ion-label>
        <ion-input v-model="cellNumber" placeholder="Cell" type="number" class="input-container-input"></ion-input>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-text-left ion-align-self-center input-container">
        <ion-label position="stacked" class="input-container-label">Insurer</ion-label>
        <ion-select :interface-options="customActionSheetOptions" interface="action-sheet" v-model="insurer" class="input-container-select" placeholder="Select Insurer">
          <ion-select-option v-for="(item, i) in insurers" :key="i" :value="item.name">{{item.description}}</ion-select-option>
        </ion-select>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12" class="ion-text-left ion-align-self-center input-container">
        <ion-label class="input-container-label">
          Photo of Client
        </ion-label>
        <ion-card class="upload-container">
          <ion-row class="ion-align-items-center">
            <ion-col class="ion-text-center ion-align-self-center">
              <ion-card-content>
                <div class="fingerprint-image-container" v-if="facePhoto !== undefined">
                  <ion-img class="full-image" v-bind:src="this.facePhoto" alt="Face Photo" />
                </div>
                <ion-label v-else>
                  nothing uploaded
                </ion-label>
              </ion-card-content>
              <div>
                <ion-button shape="round" color="primary" v-if="facePhoto !== undefined" @click="takeFacePhoto()">
                  Retake
                </ion-button>
                <ion-button shape="round" color="primary" v-else @click="takeFacePhoto()">
                  Take Photo
                </ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="6" class="ion-text-left ion-align-self-center input-container">
        <ion-label class="input-container-label">
          Left Thumb
        </ion-label>
        <ion-card class="upload-container">
          <ion-row class="ion-align-items-center">
            <ion-col size="12" class="ion-text-center ion-align-self-center">
              <ion-card-content>
                <div class="fingerprint-image-container" v-if="leftThumb && leftThumb.processedFingerprintDataUri !== '' && leftThumb.processedFingerprintDataUri !== undefined">
                  <ion-img class="fingerprint-image" :src="leftThumb.processedFingerprintDataUri" alt="Left Thumb"></ion-img>
                </div>
                <ion-label v-else>
                  nothing uploaded
                </ion-label>
              </ion-card-content>
              <div>
                <ion-button shape="round" color="primary" v-if="leftThumb && leftThumb.processedFingerprintDataUri !== '' && leftThumb.processedFingerprintDataUri !== undefined" @click="takeLeftThumbPhoto()">
                  Retake
                </ion-button>
                <ion-button shape="round" color="primary" v-else @click="takeLeftThumbPhoto()">
                  Take Photo
                </ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
      <ion-col size="6" class="ion-text-left ion-align-self-center input-container">
        <ion-label class="input-container-label">
          Right Thumb
        </ion-label>
        <ion-card class="upload-container">
          <ion-row class="ion-align-items-center">
            <ion-col class="ion-text-center ion-align-self-center">
              <ion-card-content>
                <div class="fingerprint-image-container" v-if="rightThumb && rightThumb.processedFingerprintDataUri !== '' && rightThumb.processedFingerprintDataUri !== undefined">
                  <ion-img class="fingerprint-image" :src="rightThumb.processedFingerprintDataUri" alt="Right Thumb"></ion-img>
                </div>
                <ion-label v-else>
                  nothing uploaded
                </ion-label>
              </ion-card-content>
              <div>
                <ion-button shape="round" color="primary" v-if="rightThumb && rightThumb.processedFingerprintDataUri !== '' && rightThumb.processedFingerprintDataUri !== undefined" @click="takeRightThumbPhoto()">
                  Retake
                </ion-button>
                <ion-button shape="round" color="primary" v-else @click="takeRightThumbPhoto()">
                  Take Photo
                </ion-button>
              </div>
            </ion-col>
          </ion-row>
        </ion-card>
      </ion-col>
    </ion-row>
  </form>
  <ion-row style="margin-top: 15px;">
    <ion-col size="6" class="ion-text-left ion-align-self-center">
      <ion-button shape="round" fill="outline" color="primary" class="create-mandate-btn" @click="reset()">
        Reset
      </ion-button>
    </ion-col>
    <ion-col size="6" class="ion-text-right ion-align-self-center">
      <ion-button shape="round" color="primary" class="create-mandate-btn" @click="nextStep()">
        Continue
      </ion-button>
    </ion-col>
  </ion-row>
</ion-grid>
</template>

<script>
import {
  defineComponent
} from 'vue';
import eventBus from "../services/events";
import alertsService from "../services/alerts";
import store from "../services/store";
import {
  Device
} from '@capacitor/device';
import {
  Camera,
  CameraResultType
} from '@capacitor/camera';
import axios from 'axios';
import { environment } from '@/environments/environments';
import storageService from '@/services/storage';
import { loadingController } from '@ionic/vue';

export default defineComponent({
  name: 'CreateMandateCaptureClient',
  props: {},
  components: {},
  data() {
    return {
      insurers: [],
      powerOfAttorney: false,
      firstName: '',
      surname: '',
      idNumber: '',
      cellNumber: '',
      insurer: undefined,
      facePhoto: undefined,
      facePhotoString: ''
      // Biometrics Here
    }
  },
  methods: {
    reset() {
      this.powerOfAttorney = false;
      this.firstName = '';
      this.surname = '';
      this.idNumber = '';
      this.cellNumber = '';
      this.insurer = undefined;
      this.facePhoto = undefined;
      this.facePhotoString = '';
      // Biometrics Here
    },
    async nextStep() {
      if (!(this.firstName && this.surname && this.idNumber && this.cellNumber && this.insurer)) {
        alertsService.showAlert('Error', 'Invalid details entered. Please enter the required details.');
        return;
      }
      if (this.facePhoto === undefined) {
        alertsService.showAlert('Error', 'Please make sure that a face picture has been captured.');
        return;
      }
      // Biometrics Here
      // if (this.checkBiometricData(this.leftThumb) === false || this.checkBiometricData(this.rightThumb) === false) {
      //   alertsService.showAlert('Error', 'Please make sure that both thumbs have been captured.');
      //   return;
      // }
      store.setCaptureMandateClientDetails({
        insurer: this.insurer,
        surname: this.surname,
        firstName: this.firstName,
        initials: this.firstName[0]
      });
      const deviceId = await Device.getId();
      store.setCaptureMandateBiometrics({
        deviceId: deviceId.uuid,
        idNumber: this.idNumber,
        // Biometrics Here
        leftTemplate: '',
        leftWsq: '',
        rightTemplate: '',
        rightWsq: '',
        face: this.facePhotoString,
        voice: ''
      });
      eventBus().emitter.emit("create-mandate-next-step", {
        fromStep: 'captureClient'
      });
    },
    async getMembersOf() {
      const loading = await loadingController.create({
        message: 'Loading, Please Wait',
      });
      await loading.present();
        const userAuth = await storageService.getStorage('userAuth', true);
        const userMe = await storageService.getStorage('userMe', true);
      return new Promise((resolve) => {
        const headers = {
          "Ocp-Apim-Subscription-Key": environment.ocpApimSubscriptionKey
        }
        const data = {
          TokenType: userAuth.tokenType,
          Token: userAuth.accessToken,
          UserID: userMe.id
        }
        axios.defaults.timeout = 30000
        axios.post(environment.justsolveAPIM + '/memberof', data, {
          headers
        }).then((response) => {
          loadingController.dismiss();
          resolve(response.data);
        }).catch(() => {
          loadingController.dismiss();
          resolve(false);
        })
      });
    },
    togglePowerOfAttorney(toggle) {
      this.powerOfAttorney = toggle;
    },
    async takeFacePhoto() {
      const image = await Camera.getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.DataUrl,
      });
      var du = image.dataUrl;
      var arr = du.split(',');
      this.facePhotoString = arr[1];
      this.facePhoto = du;
    },
    async takeLeftThumbPhoto() {
      // Biometrics Here
    },
    async takeRightThumbPhoto() {
      // Biometrics Here
    },
    checkBiometricData() {
      // Biometrics Here
    },
    async initInsurers() {
      this.insurers = [];
      const memberOfResults = await this.getMembersOf();
      if (memberOfResults && (memberOfResults.statusCode === 200 || memberOfResults.statusCode === '200')) {
        for (let i = 0; i < memberOfResults.data.groups.length; i++) {
          this.insurers.push({
            name: memberOfResults.data.groups[i].name,
            description: memberOfResults.data.groups[i].description
          });
        }
      } else if (memberOfResults.message) {
        alertsService.showAlert('Error', memberOfResults.message);
      } else {
        alertsService.showAlert('Error', 'An error has occurred, please try again or contact us.');
      }
    }
  },
  mounted() {
    this.initInsurers();
  }
});
</script>

<style scoped>

</style>
